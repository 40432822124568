import logger from '../../helpers/logger'
import EventEmitter from 'events'
import {io, Socket} from 'socket.io-client'
import {
  coreUrl,
  socketPath,
} from '../../configuration'
import {ready} from './type'
import store from '../../store'
import {handleLogout} from '../../store/actions'

class SocketManager extends EventEmitter {
  private _socket: Socket
  public subscribedChannels: string[]
  private _socketReady: boolean

  constructor() {
    super()

    this._socket = io(coreUrl || '', {
      autoConnect: false,
      path: socketPath,
      query: {
        token: sessionStorage.getItem('token') || ''
      }
    })
    this.subscribedChannels = []
    this._socketReady = false
    this.addEventsListeners()
  }

  //region getter/setter
  public get socket(): Socket {
    return this._socket
  }

  public get socketReady(): boolean {
    return this._socketReady
  }

  //endregion

  addEventsListeners(): Socket {
    this.socket
      .on('connect', () => {
        logger.debug('websocket open')
      })
      .on('force-logout', () => {
        logger.debug('forcing logout')
        store.dispatch(handleLogout() as any)
      })
      .on('reconnect_attempt', () => {
        //@ts-ignore
        this.socket.io.opts.query = `token=${sessionStorage.getItem('token')}`
      })
      .on('socket error', (message) => {
        logger.error(message)
      })
      .onAny((eventName, payload) => {
        this.emit(eventName, payload)
      })
    return this.socket
  }

  connect() {
    //@ts-ignore
    this.socket.io.opts.query = `token=${sessionStorage.getItem('token')}`

    return new Promise<void>((resolve, reject) => {
      const handleError = () => {
        this._socketReady = false
        reject(new Error('Could not open the socket'))
      }
      this.socket.on(ready, () => {
        this.subscribedChannels = []
        this.socket.off('error', handleError)
        this._socketReady = true
        this.emit('websocket-open')
        resolve()
      })
      this.socket.once('error', handleError)
      this.socket.open()
    })
  }


  disconnect() {
    return new Promise<void>((resolve, reject) => {
      const handleError = () => reject(new Error('Could not close the socket'))
      this.socket.once('disconnect', () => {
        this.socket.off('error', handleError)
        this.subscribedChannels = []
        this._socketReady = false
        resolve()
      })
      this.socket.once('error', handleError)
      this.socket.close()
    })
  }

  listen(event: string, callback: (...args: any[]) => void) {
    logger.info(`ON EVENT: ${event}`)
    this.socket.on(event, callback)
    return () => this.socket.off(event, callback)
  }


  send(event: string, payload?: any, callback?: (value: any)=>void): Socket {
    logger.info(`EMIT EVENT: ${event}`, payload)
    this.socket.emit(event, payload, callback)
    return this.socket
  }


  subscribe(channels: string[]): Socket {
    if (!channels.length) return this.socket
    this.send('join', channels)
    this.subscribedChannels.push(...channels)
    logger.info(`SUBSCRIBED TO: ${channels.join(', ')}`)
    return this.socket
  }

  unsubscribe(channels: string[]) {
    channels.forEach((channel) => {
      const idx = this.subscribedChannels.findIndex((c) => channel === c)
      if (idx !== -1) this.subscribedChannels.splice(idx, 1)
    })
    const channelsToUnsubscribe = channels.filter((c) => !this.subscribedChannels.includes(c))
    if(channelsToUnsubscribe.length) {
      this.send('leave', channelsToUnsubscribe)
      logger.info(`UNSUBSCRIBED TO: ${channelsToUnsubscribe.join(', ')}`)
    }
    return this.socket
  }

  removeListeners = (events: string[]) => {
    logger.debug('Removing listeners on event', events.join(' , '))
    events.forEach((e) => {
      const listeners = this.socket.listeners(e)
      listeners.forEach((l) => this.socket.off(e, l))
    })
  }

}

const socketManager = new SocketManager()

export default socketManager
