import React, {useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {InstanceType, SocialInstanceInfo} from '../../SocialSummary'
import {MenuItem} from '@mui/material'
import CommandButtons from '../CommandButtons'
import {Department, iSocial} from '../../../../../../api/types'
import {useDispatch, useSelector} from 'react-redux'
import {useMatch, useNavigate} from 'react-router-dom'
import useCoordinatorRest from '../../../../../../hooks/useCoordinatorRest'
import {useForm} from 'react-hook-form'
import StatusInfo from '../StatusInfo'
import Paper from '@mui/material/Paper'
import SocialHeader from '../SocialHeader'
import {DateTime} from 'luxon'
import {coreUrl} from '../../../../../../configuration'
import {CardWithButtons, Input, OneItemRow, Select, Switch, ThreeItemsRow} from '../../../../../common'
import {logger} from '../../../../../../helpers'
import {getTranslation} from '../../../../../../store/selectors'
import {setTitle} from '../../../../../../store/applicationState/actions'


interface Props{
  instance: SocialInstanceInfo | null
  departments: Department[]
  id?: number | string
}

interface FormData {
  name: string,
  accessToken: string,
  department: string,
  accountId: string,
  pageId: string,
  webhookUrl: string
  autoRun: boolean
  verifyToken: string
}

const defaultValues: FormData = {
  name: '',
  accessToken: '',
  department: '',
  accountId: '',
  pageId: '',
  webhookUrl:`${coreUrl}/messenger/webhook`,
  autoRun: false,
  verifyToken: 'Secret!'
}


const FacebookDetails: React.FC<Props> = ({ instance, departments, id}) => {
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch('administration/social/:type/:id')
  const typeParam = match?.params?.type


  const {
    post: createFacebook,
    put: updateFacebook,
    result: facebook,
    getById: getFacebookById,
    remove: removeFacebook,
    setResult: setFacebook
  } = useCoordinatorRest<iSocial>('socials')

  const status = useMemo(()=> facebook?.isRunning , [facebook])

  const {
    control,
    handleSubmit,
    reset,
    formState: {isDirty},
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {...defaultValues},
    shouldUnregister: false
  })

  const isNew = useMemo(() => id === 'new', [id])

  const onSubmit = useCallback((data: FormData) => {
    const dataToSubmit = {
      name: data.name || '',
      accessToken: data.accessToken ||'',
      departmentId: data.department ,
      accountId: data.accountId ||'',
      pageId: data.pageId || '',
      webhookUrl: data.webhookUrl || '',
      autoRun: data.autoRun,
      verifyToken: data.verifyToken,
      type: InstanceType.Facebook
    }
    if(isNew) {
      createFacebook(dataToSubmit).then(({payload}) => {
        navigate(`/administration/social/${payload.type}/${payload.id}`)
      }).catch((err) => logger.error(err))
    } else {
      updateFacebook(id, dataToSubmit).then(({payload}) => setFacebook(payload)).catch((err) => logger.error(err))
    }
  }, [createFacebook, id, isNew, navigate, setFacebook, updateFacebook])

  const refreshFields = useCallback(() => {
    reset({
      name: facebook?.name || '',
      accessToken: facebook?.accessToken || '',
      department: String(facebook?.departmentId) || '',
      accountId: facebook?.accountId || '',
      pageId: facebook?.pageId || '',
      webhookUrl: facebook?.webhookUrl || defaultValues.webhookUrl,
      autoRun: facebook?.autoRun || false,
      verifyToken: facebook?.verifyToken || ''
    })
  }, [reset, facebook])

  const toggleInstance = useCallback(()=>{
    if(facebook && facebook.isRunning){
      updateFacebook( id , {}, {afterPath: '/stop-instance' }).then((res)=>{
        setFacebook(res.payload)
      })
    }else{
      updateFacebook( id , {}, {afterPath: '/start-instance' }).then((res)=>{
        setFacebook(res.payload)
      })
    }
  },[updateFacebook, id, facebook, setFacebook])


  //Gestione titolo Pagina
  useEffect(() => {
    dispatch(
      setTitle({
        title: `${translation.menu.social}/${facebook?.name || translation.pages.ADMINISTRATION_SOCIAL.new} ${typeParam}`,
        breadcrumb: [ '/administration/social', `/administration/social/Facebook/${id}`]
      })
    )
  }, [translation, dispatch, id, facebook, instance?.type ,typeParam])

  useEffect(() => {
    if (id === 'new') return
    getFacebookById({id: id}).catch((e) => logger.error(e))
  }, [id, getFacebookById])


  useEffect(() => {
    refreshFields()
  }, [refreshFields])

  return (
    <Container>
      <MainFormContainer isNew={isNew}>
        {instance && <SocialHeader type={InstanceType.Facebook} isTokenExpired={
          // instance?.isTokenExpired
          false
        } />}
        <form onSubmit={handleSubmit(onSubmit)} style={{height: "auto"}}>
          <CardWithButtons
            entity="social"
            title={!isNew ? `${translation.pages.ADMINISTRATION_SOCIAL.details} ${facebook.name}` : translation.pages.ADMINISTRATION_SOCIAL.new}
            isNew={isNew}
            closePath={`/administration/social`}
            isDirty={isDirty}
            withScrollBar={true}
            refreshFields={refreshFields}
            remove={() => removeFacebook(facebook.id)
              .then(() => navigate(`/administration/social`))
              .catch((err) => logger.error(err))}
            sx={{height: 'fit-content'}}>
            <CardContainer isNew={isNew}>
              <InputsSection>
                <ThreeItemsRow>
                  <Input<FormData>
                    name="name"
                    control={control}
                    label={`${translation.input.name} *`}
                  />
                  <Select<FormData>
                    name={'department'}
                    control={control}
                    label={`${translation.input.departments} *`}>
                    {departments?.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Switch<FormData>
                    name="autoRun"
                    control={control}
                    labelPosition={'left'}
                    label={translation.input.autoRun}>
                  </Switch>
                </ThreeItemsRow>
                <ThreeItemsRow>
                  <Input<FormData>
                    name="pageId"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.pageId} *`}
                  />
                  <Input<FormData>
                    name="accountId"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.userId} *`}
                  />
                  <Input<FormData>
                    name="verifyToken"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.verifyToken}`}
                  />
                </ThreeItemsRow>
                <OneItemRow>
                  <Input<FormData>
                    name="webhookUrl"
                    InputProps={{
                      readOnly: true,
                    }}
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.webhookUrl} *`}
                  />

                </OneItemRow>
                <OneItemRow>
                  <Input<FormData>
                    name="accessToken"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.accessToken} *`}
                    hint={facebook?.tokenExpiresAt ? `${translation.pages.ADMINISTRATION_SOCIAL.tokenValidUntil} ${DateTime.fromISO(facebook?.tokenExpiresAt).toFormat('dd-LLL-yyyy HH:mm:ss')}` : undefined}
                  />
                </OneItemRow>
              </InputsSection>
            </CardContainer>
          </CardWithButtons>
        </form>
        {instance && !isNew && <CustomPaper>
          <InfoContainer>
              <StatusInfo status={status} instance={instance} withoutAuth={true}/>
            {id && !isNew && <CommandButtons toggleInstance={()=>toggleInstance()} status={status} instance={instance}/>}
          </InfoContainer>
        </CustomPaper>}
      </MainFormContainer>
    </Container>
  )
}

export default FacebookDetails




// region style
const Container = styled.div`
  height: 100%;
  width: 100%;
`

const MainFormContainer = styled.div<{ isNew: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  
`

const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.div<{ isNew: boolean }>`
  display: flex;
  flex-direction: column;

`

const CustomPaper = styled(Paper)`
    width: min-content;
    padding: 12px 8px;
`

const InfoContainer = styled.div`
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
`

// endregion
