import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {InstanceType, SocialInstanceInfo} from '../../SocialSummary'
import {MenuItem} from '@mui/material'
import CommandButtons from '../CommandButtons'
import {useMatch, useNavigate} from 'react-router-dom'
import useCoordinatorRest from '../../../../../../hooks/useCoordinatorRest'
import {Department, iSocial} from '../../../../../../api/types'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import StatusInfo from '../StatusInfo'
import Paper from '@mui/material/Paper'
import SocialHeader from '../SocialHeader'
import {getTranslation} from '../../../../../../store/selectors'
import {setTitle} from '../../../../../../store/applicationState/actions'
import {logger} from '../../../../../../helpers'
import {CardWithButtons, Input, Select, Switch, ThreeItemsRow} from '../../../../../common'


interface Props{
  instance: SocialInstanceInfo | null
  departments: Department[]
  id?: number | string
}

const TelegramDetails: React.FC<Props> = ({ instance, departments, id}) => {
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch('administration/social/:type/:id')
  const typeParam = match?.params?.type
  const [instanceStatus, setInstanceStatus] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)
  const {
    post: createTelegram,
    put: updateTelegram,
    result: telegram,
    getById: getTelegram,
    remove: removeTelegram,
    setResult: setTelegram
  } = useCoordinatorRest<iSocial>('socials')

  // const { post: sendAuthCode} = useCoordinatorRest<iTelegram>('socials/telegram')

  const {
    control,
    handleSubmit,
    reset,
    formState: {isDirty},
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultValues },
    shouldUnregister: false
  })

  const isNew = useMemo(() => id === 'new', [id])

  const refreshFields = useCallback(() => {
    reset({
      name: telegram?.name || '',
      apiId: telegram?.apiId || '',
      department: telegram?.departmentId ? String(telegram?.departmentId) : '',
      apiHash: telegram?.apiHash || '',
      phoneNumber: telegram?.phoneNumber || '',
      autoRun: telegram?.autoRun || false
    })
  }, [reset, telegram])


  const onSubmit = useCallback((data: FormData) => {
    const dataToSubmit = {
      name: data.name,
      departmentId: data.department,
      apiId: data.apiId,
      apiHash: data.apiHash,
      phoneNumber: data.phoneNumber,
      autoRun: data.autoRun,
      type: InstanceType.Telegram
    }
    if(isNew) {
      createTelegram(dataToSubmit).then(({payload}) => {
        navigate(`/administration/social/${payload.type}/${payload.id}`)
      }).catch((err) => logger.error(err))
    } else {
      updateTelegram(id, dataToSubmit).then(({payload}) => setTelegram(payload)).catch((err) => logger.error(err))
    }
  }, [createTelegram, id, isNew, navigate, setTelegram, updateTelegram])

/*  const submitAuthCode = useCallback((data: { code: string})=> {
    sendAuthCode(data, {afterPath: `/${id}/authCode`}).catch(() => dispatch(notify({ message: translation.pages.TELE, severity: 'error' })))
  }, [id, dispatch, sendAuthCode, translation])*/

  useEffect(() => {
    if (id === 'new') return
    getTelegram({id: id}).then((res)=> setInstanceStatus(res.payload?.isRunning)).catch((e) => logger.error(e))
  }, [id, getTelegram])

  useEffect(() => {
    refreshFields()
  }, [refreshFields])

  //Gestione titolo Pagina
  useEffect(() => {
    dispatch(
      setTitle({
        title: `${translation.menu.social}/${telegram?.name || translation.pages.ADMINISTRATION_SOCIAL.new }/${typeParam}`,
        breadcrumb: [ '/administration/social', `/administration/social/Telegram/${id}`]
      })
    )
  }, [translation, dispatch, id, telegram, instance?.type, typeParam])

  return <Container>
    <MainFormContainer  isNew={isNew}>
      {instance && <SocialHeader type={InstanceType.Telegram} isTokenExpired={
        // instance?.isTokenExpired
        false
      } />}
{/*
      <AuthenticationModal isAuth={!!instance?.verified} submit={submitAuthCode} modalOpen={modalOpen} setModalOpen={(value: boolean)=> setModalOpen(value) } qrCode={instance?.qrCode}/>
*/}
      <form onSubmit={handleSubmit(onSubmit)} style={{height: "auto"}}>
        <CardWithButtons
          entity="social"
          title={!isNew ? `${translation.pages.ADMINISTRATION_SOCIAL.details} ${telegram.name}` : translation.pages.ADMINISTRATION_SOCIAL.new}
          isNew={isNew}
          closePath={`/administration/social`}
          isDirty={isDirty}
          withScrollBar={true}
          refreshFields={refreshFields}
          remove={() =>  removeTelegram(telegram.id)
            .then(() => navigate (`/administration/social`))
            .catch((err) => logger.error(err))}
          sx={{height: 'fit-content'}}>
          <CardContainer isNew={isNew}>
            <InputsSection>
              <ThreeItemsRow>
                <Input<FormData>
                  name="name"
                  control={control}
                  label={`${translation.input.name} *`}
                />
                <Select<FormData>
                  name={'department'}
                  control={control}
                  label={`${translation.input.departments} *`}
                >
                  {departments?.map((d, index) => (
                    <MenuItem key={index} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </Select>
                <Switch<FormData>
                  name="autoRun"
                  control={control}
                  labelPosition={'left'}
                  label={translation.input.autoRun}>
                </Switch>
              </ThreeItemsRow>
              <ThreeItemsRow>
                <Input<FormData>
                  name="apiId"
                  control={control}
                  label={`${translation.pages.ADMINISTRATION_SOCIAL.apiId} *`}
                />
                <Input<FormData>
                  name="apiHash"
                  control={control}
                  label={`${translation.pages.ADMINISTRATION_SOCIAL.apiHash}`}
                />
                <Input<FormData>
                  name="phoneNumber"
                  control={control}
                  label={`${translation.pages.ADMINISTRATION_SOCIAL.phoneNumber}`}
                />
              </ThreeItemsRow>
            </InputsSection>
          </CardContainer>
        </CardWithButtons>
      </form>
      {instance && !isNew && <CustomPaper>
          <InfoContainer>
              <StatusInfo  status={instanceStatus} setModalOpen={(value: boolean)=>setModalOpen(value)} instance={instance}/>
            {id && !isNew && <CommandButtons toggleInstance={()=>null} status={instanceStatus} instance={instance}/>}
          </InfoContainer>
      </CustomPaper>}
  </MainFormContainer>
</Container>
}

export default TelegramDetails



// region style
const Container = styled.div`
    height: 100%;
    width: 100%;
`

const CustomPaper = styled(Paper)`
    width: min-content;
    padding: 12px 8px;
`

const MainFormContainer = styled.div<{ isNew: boolean }>`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;

`

const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.div<{ isNew: boolean }>`
  display: flex;
  flex-direction: column;

`

const InfoContainer = styled.div`
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
`
// endregion



const defaultValues: FormData = {
  name: '',
  department: '',
  apiId: '',
  apiHash: '',
  phoneNumber: '',
  autoRun: false
}


interface FormData {
  name: string,
  department: string,
  apiId: string,
  apiHash: string,
  phoneNumber: string,
  autoRun: boolean
}