import React, {createContext, ReactNode, useCallback, useState} from 'react'
import {Backdrop, CircularProgress, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {getTranslation} from '../../store/selectors'

type LoaderState = {
  loadingMessage: string
  pageLoading: boolean
}

export const LoaderContext = createContext<{
  state: LoaderState
  setPageLoading: (
    pageLoading: boolean,
    message?: string
  ) => void
} | null>(null)

const initialState: LoaderState = {
  loadingMessage: '',
  pageLoading: false,
}

export function LoaderProvider({children}: { children: ReactNode }) {
  const [state, setState] = useState(initialState)
  const translation = useSelector(getTranslation)

  const setPageLoading = useCallback(
    (
      pageLoading: boolean,
      message?: string
    ) => {
      setState((oldState) => ({...oldState, pageLoading, loadingMessage: !pageLoading ? '' : (message || translation.input.loading)}))
    },
    [setState, translation.input.loading]
  )

  return (
    <LoaderContext.Provider value={{state, setPageLoading}}>
      {children}
      {(state.pageLoading) && (
        <Backdrop
          sx={{
            color: '#ffffff',
            zIndex: 9999,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column'
          }}
          open={true}
        >
          <CircularProgress
            sx={{color: '#ffffff'}}
            size={'3rem'}
          />
          {state.loadingMessage && (
            <Typography sx={{mt: 2}} color="inherit">
               {state.loadingMessage}
            </Typography>
          )}
        </Backdrop>
      )}
    </LoaderContext.Provider>
  )
}

