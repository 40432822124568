import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getTranslation} from '../../store/selectors'
import {Entities, useMonitoringCalls, useMonitoringChats, useSocket} from '../../hooks'
import {TwoItemsRow} from './FormRows'
import {NumberPlot} from './NumberPlot'
import {FractionPlot} from './FractionPlot'
import styled from 'styled-components'
import CallsStatus from '../pages/tools/dashboard/CallsStatus'

export const CallsAndChatsSideColumn: React.FC = () => {

  const translation = useSelector(getTranslation)

  const calls = useMonitoringCalls()
  const chats = useMonitoringChats()
  const { entities: { providers: providersFromSocket }} = useSocket(providersSocketChannelsToJoin)

  const busyChannels = useMemo(() => providersFromSocket?.reduce((acc, provider) => acc + (provider.busyChannels || 0), 0) || 0, [providersFromSocket])
  const allChannels = useMemo(() => providersFromSocket?.reduce((acc, provider) => acc + (provider.channels || 0), 0) || 0, [providersFromSocket])
  const callsInPreQueue = useMemo(() => [...calls.values()].reduce((acc, call) => acc + (call.type === 'inbound' && call.inbounds.length && !call.queues.length && !call.users.length ? 1 : 0), 0) || 0, [calls])

  /** Informazioni real-time parte chat **/
  const waitingChats = useMemo(() => [...chats.values()].reduce((acc, chat) => acc + (chat.departments.length && !chat.operator ? 1 : 0), 0), [chats])
  const chatsInProgress = useMemo(() => [...chats.values()].reduce((acc, chat) => acc + (chat.departments.length && chat.operator ? 1 : 0), 0), [chats])

  return (
    <ColumnContainer>
      <CallsStatus
        showCallsInPreQueue={false}
        showColors={false}
      />
      <TwoItemsRow style={{ height: '30%', justifyContent: 'space-between' }}>
        <NumberPlot title={translation.input.callsInPreQueue} number={callsInPreQueue}/>
        <FractionPlot title={translation.input.busyChannels} numerator={busyChannels} denominator={allChannels} />
      </TwoItemsRow>
      <TwoItemsRow style={{ height: '30%', justifyContent: 'space-between' }}>
        <NumberPlot title={translation.input.chatsInProgress} number={chatsInProgress}/>
        <NumberPlot title={translation.input.waitingChats} number={waitingChats} />
      </TwoItemsRow>
    </ColumnContainer>
  )
}

const providersSocketChannelsToJoin: (keyof Entities)[] = ['providers']

// region style
const ColumnContainer = styled.div`
  height: 100%;
  width: auto;
  padding-left: 20px;
`