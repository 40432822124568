import {useRest} from '../hooks'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Announcement, InboundRoute, Ivr, Queue, RingGroup, TimeCondition, User, Voip} from '../api/types'
import {useSelector} from "react-redux";
import {getTranslation} from "../store/selectors";

interface FollowMeDestination {
  addVoip?: string
  outboundPrefix?: string
  outboundNumber?: string
}

export interface DestinationObject {
  destType: DestTypes
  destination?: string
}

interface HookObject {
  availableDestTypes: { [key: string]: string }
  showDestination: (entry: string) => string
  parseDestination: (destination: string) => DestinationObject
  buildDestination: (entry: { destType: string; destination?: string }) => string
  getEntityData: (entityName: string) => EntityData[]
  buildFollowMe: (params: FollowMeDestination) => string
}

export interface EntityData {
  id: string
  label: string

  [key: string]: string | number | undefined
}

export interface Entities {
  'ext-queues': EntityData[]
  'from-trunk': EntityData[]
  'ext-group': EntityData[]
  'from-did-direct': EntityData[]
  'ext-local': EntityData[]
  timeconditions: EntityData[]
  'app-announcement': EntityData[]
  ivr: EntityData[]
}

export type DestTypes =
  | 'ext-queues'
  | 'from-trunk'
  | 'app-blackhole,hangup,1'
  | 'app-blackhole,busy,1'
  | 'ext-group'
  | 'from-did-direct'
  | 'ext-local'
  | 'timeconditions'
  | 'app-announcement'
  | 'ivr'
  | ''

const useConfigurationPbxHelper = (idPbx: number | string, followMe: boolean = false): HookObject => {
  const [entities, setEntities] = useState<Entities>()
  const translation = useSelector(getTranslation)

  const destsDict: { [key: string]: string } = useMemo(() => ({
    'ext-queues': translation.destTypes.queue,
    'from-trunk': translation.destTypes.inboundRoutes,
    'ext-group': translation.destTypes.shortcuts.ringGroup,
    'from-did-direct': translation.destTypes.shortcuts.extension,
    timeconditions: translation.destTypes.shortcuts.timeCondition
  }), [])
  const availableDestTypes: { [key: string]: string } = {
    'ext-queues': translation.destTypes.queue,
    'from-trunk': translation.destTypes.inboundRoutes,
    'app-blackhole,hangup,1': translation.destTypes.hangup,
    'ocrecall': translation.destTypes.ocRecall,
    'app-blackhole,busy,1': translation.destTypes.busy,
    'ext-group': translation.destTypes.ringGroup,
    'from-did-direct': translation.destTypes.extension,
    'ext-local': translation.destTypes.voiceMail,
    timeconditions: translation.destTypes.timeCondition,
    'app-announcement': translation.destTypes.announcement,
    ivr: translation.destTypes.ivr
  }

  /* region Entities*/

  const {results: ivrs} = useRest<Ivr>(`pbxs/${idPbx}/ivrs`, {lazy: followMe})
  const {results: users} = useRest<User>(`users`, {lazy: followMe})
  const {results: timeConditions} = useRest<TimeCondition>(`pbxs/${idPbx}/timeconditions`, {lazy: followMe})
  const {results: announcements} = useRest<Announcement>(`pbxs/${idPbx}/announcements`, {lazy: followMe, initialFilters: {_sortby: 'description', _sortorder: 'asc'}})
  const {results: queues} = useRest<Queue>(`pbxs/${idPbx}/queues`, {lazy: followMe})
  const {results: inboundRoutes} = useRest<InboundRoute>(`pbxs/${idPbx}/inbound-routes`, {lazy: followMe})
  const {results: voips} = useRest<Voip>(`pbxs/${idPbx}/voips`, {lazy: followMe })
  const {results: ringGroup} = useRest<RingGroup>(`pbxs/${idPbx}/ring-groups`, {lazy: followMe})

  //popolazione del oggetto contenente le entità
  useEffect(() => {


    if (followMe) return
    const sortFunction = (a: EntityData, b: EntityData) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    }

    const newEntities: Entities = {
      'ext-queues': queues.map((e) => ({
        id: e.extension,
        label: e.descr ? `${e.descr} (${e.extension})` : e.extension
      })).sort(sortFunction),
      'from-trunk': inboundRoutes.map((e) => ({id: e.extension, label: e.description})).sort(sortFunction),
      'ext-group': ringGroup.map((e) => ({id: e.grpnum, label: e.description})).sort(sortFunction),
      'from-did-direct': [],
      'ext-local': voips.filter((e: Voip) => !!e.voicemail).map((e: Voip) => ({id: String(e.extension), label: `${e.voicemail} (${e.extension})`})).sort(sortFunction),
      timeconditions: timeConditions.map((e) => ({id: `${e.timeconditions_id}`, label: e.displayname})).sort(sortFunction),
      'app-announcement': announcements.map((e) => ({id: `${e.announcement_id}`, label: e.description})),
      ivr: ivrs.map((e) => ({id: `${e.id}`, label: e.name})).sort(sortFunction)
    }

    users.forEach((u) => {
      u.userVoips?.forEach((uv) => {
        newEntities['from-did-direct'].push({
          name: u.name,
          surname: u.surname,
          id: `${typeof uv.voip as any === 'string' ? uv.voip : uv.voip.extension}`,
          label: `${u.surname} ${u.name} (${typeof uv.voip as any === 'string' ? uv.voip : uv.voip.extension})`
        })
      })
    })

    newEntities['from-did-direct'].sort(sortFunction)

    setEntities(newEntities)
  }, [ivrs, users, timeConditions, announcements, queues, ringGroup, voips, followMe])
  /*endregion*/

  /*region Destination*/
  /**
   * Funzione per mostrare il campo destination che arriva dall'agent nelle tabelle
   * @param destination {string} campo destination
   */
  const showDestination = useCallback((destination: string) => {
    if (!entities) return ''
    let [destType, destItem] = destination.split(',')

    // Se la destinazione è un hangup o un busy mi servono il destType ed il destItem per capire di che destinazione si tratta
    if (destType === 'app-blackhole') {
      return `${destItem}`
    }

    // Se la destinazione è un ivr oppure un announcement devo prendermi l'id dal destType
    if (destType.match(/^ivr.*$/g)) {
      destItem = destType.split('-')[1]

      const ivr = entities['ivr'].find((ivr) => ivr.id === destItem)

      if (!ivr) return ''
      return `[IVR] - ${ivr.label}`
    }
    if (destType.match(/^app-announcement.*$/g)) {
      const splitDest = destType.split('-')
      destItem = splitDest[2]

      const announcement = entities['app-announcement'].find((ann) => ann.id === destItem)

      if (!announcement) return ''
      return `[ANN] - ${announcement.label}`
    }
    if (destType.match(/^ext-queues.*$/g)) {
      const queue = entities['ext-queues'].find((q) => q.id === destItem)

      if (!queue) return ''
      return `[${translation.destTypes.shortcuts.queue}] - ${queue.id}`
    }
    if (destType.match(/^from-trunk.*$/g)) {
      const inbound = entities['from-trunk'].find((q) => q.id === destItem)

      if (!inbound) return ''
      return `[${translation.destTypes.shortcuts.inbound}] - ${inbound.id}`
    }
    if (destType.match(/^from-did-direct.*$/g)) {
      const user = entities['from-did-direct'].find((u) => u.id === destItem)

      if (!user) return ''
      return `[${translation.destTypes.shortcuts.extension}] - ${user.surname} ${user.name} (${user.id})`
    }

    if (destType.match(/^ext-local.*$/g)) {
      const voiceMailExtension = entities['ext-local'].find((e) => e.id === destItem.substring(3))

      if (!voiceMailExtension) return ''
      return `[VM] - ${voiceMailExtension.id}`
    }

    const entity = entities[destType as keyof Entities]?.find((e) => e.id === (destItem))

    return `[${destsDict[destType]}] - ${entity?.label || '-'}`
  }, [entities, destsDict])
  /**
   * Funzione per trasformare i campi destinazione in un valore accettato da asterisk
   * @param entry
   */
  const buildDestination = useCallback((entry: { destType: string; destination?: string }) => {
    if (!entry.destType) return ''
    let builtDest

    if (entry.destType.match(/^.*blackhole.*$/g)) {
      builtDest = `${entry.destType}`
    } else if (entry.destType.match(/^ivr.*$/g) || entry.destType.match(/^.*announcement.*$/g)) {
      builtDest = `${entry.destType}-${entry.destination},s,${idPbx}`
    } else if (entry.destType === 'ocrecall') {
      builtDest = `${entry.destType},s,${idPbx}`
    } else if(entry.destType.match(/^.*ext-local.*$/g)) {
      builtDest = `${entry.destType},vms${entry.destination}${entry.destination ? ',' : ''}${idPbx}`
    } else {
      builtDest = `${entry.destType},${entry.destination}${entry.destination ? ',' : ''}${idPbx}`
    }

    return builtDest
  }, [idPbx])
  /**
   * Funzione per trasformare la stringa che arriva dall'agent ai campi della form
   * @param destination {string} la stinga arrivata dall agent
   * @return DestinationObject
   */
  const parseDestination = useCallback((destination: string) => {
    if (!entities || !destination) return {destType: ''} as DestinationObject
    const [destType, destItem] = destination.split(',')
    const entries = {
      destType: destType,
      destination: destItem
    }

    if (destType === 'app-blackhole') {
      entries.destType = `${destType},${destItem},1`
      entries.destination = ''
    }
    if (destType.match(/^ivr.*$/g)) {
      entries.destType = destType.split('-')[0]
      entries.destination = destType.split('-')[1]
    }
    if (destType.match(/^ext-local.*$/g)) {
      entries.destination = destItem.slice(3)
    }
    if (destType.match(/^app-announcement.*$/g)) {
      entries.destType = 'app-announcement'
      entries.destination = destType.split('-')[2]
    }

    return entries as DestinationObject
  }, [entities])
  /*endregion*/

  /*region FollowMe */
  /**
   * Funzione per creare la stringa da mandare all'agent per impostare le destinazioni
   * @param addVoip
   * @param outboundPrefix
   * @param outboundNumber
   */
  const buildFollowMe = ({addVoip, outboundPrefix, outboundNumber}: FollowMeDestination) => {
    if (addVoip) return addVoip.split(' ')[3] || ''
    if (outboundPrefix && outboundNumber) return `${outboundPrefix}${outboundNumber}#`
    return ''
  }
  /* endregion */

  /**
   * getter per le entità disponibili
   * @param entityName
   */
  const getEntityData = (entityName: string): EntityData[] => {
    return entities?.[entityName as keyof Entities] || []
  }

  return {
    availableDestTypes,
    getEntityData,
    buildFollowMe,
    buildDestination,
    showDestination,
    parseDestination
  }
}

export default useConfigurationPbxHelper
