import {ReactComponent as SupportAgent} from '../images/icons/support_agent.svg'
import {
  AccessAlarmOutlined,
  AccountBoxOutlined,
  AccountBoxRounded,
  AccountTreeOutlined,
  AlbumOutlined,
  AssignmentIndOutlined,
  BarChartOutlined,
  BlockRounded,
  CallMadeRounded,
  CallMissedOutgoing,
  CallReceivedRounded,
  CallRounded,
  CampaignOutlined,
  ChatOutlined,
  ChatRounded,
  ClearAllOutlined,
  ContactPhoneRounded,
  CorporateFareOutlined,
  DashboardOutlined,
  ForumOutlined,
  GroupWorkOutlined,
  HistoryToggleOffOutlined,
  HubOutlined,
  ListAltOutlined,
  LoginOutlined,
  ManageAccountsOutlined,
  MonetizationOnRounded,
  MusicNoteOutlined,
  PauseCircleOutlined,
  PersonOutlineOutlined,
  PeopleAltOutlined,
  PhoneAndroidRounded,
  PhoneForwardedOutlined,
  PodcastsOutlined,
  PrivacyTipRounded,
  QueueRounded,
  RecentActorsRounded,
  RuleFolderOutlined,
  RuleOutlined,
  SettingsPhoneOutlined,
  StackedLineChartOutlined,
  ThumbUpAltRounded,
  Tty,
  ViewSidebarOutlined,
  VpnKeyOutlined
} from '@mui/icons-material'
import {
  Announcement,
  Announcements,
  AuthenticationMethod,
  AuthenticationMethods,
  Blacklist,
  CallsHistory,
  ChatsHistory,
  Customer,
  Customers,
  CustomersSummary,
  CustomizedMessage,
  CustomizedMessages,
  Dashboard,
  Extension,
  Extensions,
  Feedback,
  Feedbacks,
  InboundRoute,
  InboundRoutes,
  InboundsQueue,
  InboundsQueues,
  Ivr,
  Ivrs,
  Logs,
  MonitoringCallbackCalls,
  MonitoringCustomer,
  MonitoringDepartment,
  MonitoringOutbound,
  MonitoringPanel,
  MonitoringPanels,
  MonitoringPredictiveCampaigns,
  MonitoringQueue,
  MusicOnHold,
  MusicsOnHold,
  Operators,
  Organizations,
  Outbound,
  OutboundRule,
  OutboundRules,
  Outbounds,
  Pauses,
  Pbx,
  PbxQueue,
  PbxQueues,
  Pbxs,
  PredictiveCampaign,
  PredictiveCampaigns,
  PrivacyMessage,
  PrivacyMessages,
  Provider,
  Providers,
  Recording,
  Recordings,
  RingGroup,
  RingGroups,
  Role,
  Roles,
  ServiceDepartment,
  ServiceDepartments,
  Tenant,
  Tenants,
  TimeCondition,
  TimeConditions,
  TimeGroup,
  TimeGroups,
  Trunk,
  Trunks,
  User,
  Users,
  VocalOrderHistory,
} from '../components/pages'
import Stats from '../components/pages/tools/statistics/Stats'
import {IMenuItem} from './types'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import {SocialSummary} from "../components/pages/administration/social/SocialSummary";
import {SocialDetail} from "../components/pages/administration/social/SocialDetail";

export const menuItems: IMenuItem[] = [
  {
    label: 'tools',
    type: 'title',
    alt: 'tools',
    subMenu: [
      {
        label: 'dashboard',
        img: DashboardOutlined,
        pathname: 'tools/dashboard',
        alt: 'tools/dashboard',
        component: Dashboard,
        permissions: ['dashboard']
      },
      {
        label: 'operators',
        img: SupportAgent,
        pathname: 'tools/operators',
        alt: 'tools/operators',
        component: Operators,
        detail: User,
        permissions: ['operators']
      },
      {
        label: 'management',
        img: ManageAccountsOutlined,
        alt: 'management',
        subMenu: [
          {
            label: 'queues',
            img: CallReceivedRounded,
            pathname: 'tools/management/inboundQueues',
            alt: 'tools/management/inboundQueues',
            permissions: ['services-queues'],
            component: InboundsQueues,
            detail: InboundsQueue
          },
          {
            label: 'outbound',
            img: CallMadeRounded,
            pathname: 'tools/management/outbounds',
            alt: 'tools/management/outbounds',
            component: Outbounds,
            detail: Outbound,
            permissions: ['services-outbounds']
          },
          {
            label: 'chats',
            img: ChatOutlined,
            pathname: 'tools/management/chats',
            alt: 'tools/management/chats',
            component: ServiceDepartments,
            detail: ServiceDepartment,
            permissions: ['services-departments']
          }
        ]
      },
      {
        label: 'monitoring',
        img: StackedLineChartOutlined,
        alt: 'tools/monitoring',
        subMenu: [
          {
            label: 'customers',
            img: AccountBoxRounded,
            pathname: 'tools/monitoring/customers',
            alt: 'tools/monitoring/customers',
            component: MonitoringCustomer,
            permissions: ['monitoring-customers']
          },
          {
            label: 'outbound',
            img: CallMadeRounded,
            pathname: 'tools/monitoring/outbound',
            alt: 'tools/monitoring/outbound',
            component: MonitoringOutbound,
            permissions: ['monitoring-outbounds']
          },
          {
            label: 'queues',
            img: QueueRounded,
            pathname: 'tools/monitoring/queues',
            alt: 'tools/monitoring/queues',
            component: MonitoringQueue,
            permissions: ['monitoring-queues']
          },
          {
            label: 'callback',
            img: CallMissedOutgoing,
            pathname: 'tools/monitoring/callback',
            alt: 'tools/monitoring/callback',
            component: MonitoringCallbackCalls,
            permissions: ['monitoring-predictive']
          },
          {
            label: 'predictive',
            img: Tty,
            pathname: 'tools/monitoring/predictive',
            alt: 'tools/monitoring/predictive',
            component: MonitoringPredictiveCampaigns,
            permissions: ['monitoring-predictive']
          },
          {
            label: 'chats',
            img: ChatRounded,
            pathname: 'tools/monitoring/chats',
            alt: 'tools/monitoring/chats',
            component: MonitoringDepartment,
            permissions: ['monitoring-departments']
          }
        ]
      },
      {
        label: 'history',
        img: ListAltOutlined,
        alt: 'tools/history',
        subMenu: [
          {
            label: 'calls',
            img: CallRounded,
            pathname: 'tools/history/calls',
            alt: 'tools/history/chiamate',
            component: CallsHistory,
            permissions: ['history-calls']
          },
          {
            label: 'chat',
            img: ChatRounded,
            pathname: 'tools/history/chats',
            alt: 'tools/history/chat',
            component: ChatsHistory,
            permissions: ['history-chats']
          },
          {
            label: 'contracts',
            img: MonetizationOnRounded,
            pathname: 'tools/history/contracts',
            alt: 'tools/history/contracts',
            component: VocalOrderHistory,
            permissions: ['history-vocalorders']
          }
        ]
      },
      {
        label: 'stats',
        img: BarChartOutlined,
        pathname: 'tools/stats',
        alt: 'tools/stats',
        component: Stats,
        permissions: ['stats']
      }
    ]
  },
  {
    label: 'administration',
    type: 'title',
    alt: 'administration',
    subMenu: [
      {
        label: 'tenants',
        img: PeopleAltOutlined,
        pathname: 'administration/tenants',
        alt: 'administration/tenants',
        component: Tenants,
        detail: Tenant,
        permissions: ['tenants']
      },
      {
        label: 'users',
        img: PersonOutlineOutlined,
        pathname: 'administration/users',
        alt: 'administration/users',
        component: Users,
        detail: User,
        permissions: ['users']
      },
      {
        label: 'customers',
        img: AssignmentIndOutlined,
        alt: 'administration/customers',
        subMenu: [
          {
            label: 'management',
            img: RecentActorsRounded,
            pathname: 'administration/customers/management',
            alt: 'administration/customers/management',
            component: Customers,
            detail: Customer,
            permissions: ['customers']
          },
          {
            label: 'summary',
            img: ContactPhoneRounded,
            pathname: 'administration/customers/summary',
            alt: 'administration/customers/summary',
            permissions: ['customers'],
            component: CustomersSummary
          }
        ]
      },
      {
        label: 'roles',
        img: RuleFolderOutlined,
        pathname: 'administration/roles',
        alt: 'administration/roles',
        last: true,
        component: Roles,
        detail: Role,
        permissions: ['roles']
      },
      {
        label: 'organizations',
        img: CorporateFareOutlined,
        pathname: 'administration/organizations',
        alt: 'administration/organizations',
        component: Organizations,
        permissions: ['organizations']
      },
      // {
      //   label: 'crm',
      //   img: WebOutlined,
      //   pathname: 'administration/crms',
      //   alt: 'administration/crms',
      //   permissions: ['crms'],
      //   component: CRMs,
      //   detail: CRM
      // },
      // {
      //   label: 'operatorTemplate',
      //   img: ManageAccountsOutlined,
      //   pathname: 'administration/operatorTemplate',
      //   alt: 'administration/operatorTemplate',
      //   permissions: ['operators-template']
      // },
      {
        label: 'providers',
        img: PodcastsOutlined,
        pathname: 'administration/providers/phone',
        alt: 'administration/providers/phone',
        permissions: ['providers'],
        component: Providers,
        detail: Provider
      },
      {
        label: 'pauses',
        img: PauseCircleOutlined,
        pathname: 'administration/pauses',
        alt: 'administration/pauses',
        component: Pauses,
        permissions: ['pauses']
      },
      {
        label: 'chat',
        img: ForumOutlined,
        alt: 'administration/chat',
        subMenu: [
          {
            label: 'customizedMessages',
            img: ChatRounded,
            pathname: 'administration/chat/customizedMessages',
            alt: 'administration/chat/customizedMessages',
            permissions: ['customized-messages'],
            component: CustomizedMessages,
            detail: CustomizedMessage
          },
          {
            label: 'privacyMessages',
            img: PrivacyTipRounded,
            pathname: 'administration/chat/privacyMessages',
            alt: 'administration/chat/privacyMessages',
            permissions: ['privacy-messages'],
            component: PrivacyMessages,
            detail: PrivacyMessage
          },
          {
            label: 'feedback',
            img: ThumbUpAltRounded,
            pathname: 'administration/chat/surveys',
            alt: 'administration/chat/surveys',
            permissions: ['surveys'],
            component: Feedbacks,
            detail: Feedback
          },
          // {
          //   label: 'surveyQuestionType',
          //   img: Poll,
          //   pathname: 'administration/chat/surveyQuestionType',
          //   alt: 'administration/chat/surveyQuestionType',
          //   permissions: ['surveys'],
          //   component: SurveyQuestionTypes,
          //   detail: SurveyQuestionType
          // }
        ]
      },
      {
        label: 'social',
        img: ConnectWithoutContactIcon,
        pathname: 'administration/social',
        detailPathName: 'administration/social/:type',
        alt: 'administration/social',
        component: SocialSummary,
        detail: SocialDetail,
        permissions: ['social']
      },
      {
        label: 'monitoringPanels',
        img: ViewSidebarOutlined,
        pathname: 'administration/monitoring-panels',
        alt: 'administration/monitoringPanels',
        component: MonitoringPanels,
        detail: MonitoringPanel,
        permissions: ['monitoring-panels']
      },
      // {
      //   label: 'addressBooks',
      //   img: ContactMailOutlined,
      //   alt: 'administration/addressBooks',
      //   permissions: ['addressbooks'],
      //   subMenu: [
      //     {
      //       label: 'contacts',
      //       img: ContactPhoneRounded,
      //       pathname: 'administration/addressBooks/contacts',
      //       alt: 'administration/addressBooks/contacts'
      //     }
      //   ]
      // },
      {
        label: 'log',
        img: AccountBoxOutlined,
        pathname: 'administration/logs',
        alt: 'administration/log',
        component: Logs,
        permissions: ['actionlogs']
      },
      {
        label: 'authenticationMethods',
        img: VpnKeyOutlined,
        pathname: 'administration/authentication-methods',
        alt: 'administration/authentication-methods',
        component: AuthenticationMethods,
        detail: AuthenticationMethod,
        permissions: ['authentication-methods']
      },
      {
        label: 'predictive',
        img: PhoneForwardedOutlined,
        pathname: 'administration/predictive',
        component: PredictiveCampaigns,
        detail: PredictiveCampaign,
        alt: 'administration/predictive',
        permissions: ['predictive']
      },
      {
        label: 'pbx',
        img: SettingsPhoneOutlined,
        alt: 'administration/pbxs',
        pathname: 'administration/pbxs',
        component: Pbxs,
        detail: Pbx,
        permissions: ['pbxs']
      },
      {
        label: 'outboundRules',
        img: RuleOutlined,
        alt: 'administration/outboundRules',
        pathname: 'administration/outboundRules',
        component: OutboundRules,
        detail: OutboundRule,
        permissions: ['outbound-rules']
      }
    ]
  },
  {
    label: 'configuration',
    type: 'title',
    alt: 'configuration',
    subMenu: [
      {
        label: 'extensions',
        img: PhoneAndroidRounded,
        pathname: 'configuration/:pbxId/extensions',
        alt: 'extensions',
        component: Extensions,
        detail: Extension,
        permissions: ['pbxs-extensions']
      },
      {
        label: 'ringGroups',
        img: GroupWorkOutlined,
        pathname: 'configuration/:pbxId/ring-groups',
        alt: 'ring groups',
        component: RingGroups,
        detail: RingGroup,
        permissions: ['pbxs-ring-groups']
      },
      {
        label: 'inboundRoutes',
        img: LoginOutlined,
        pathname: 'configuration/:pbxId/inbound-routes',
        alt: 'inbound routes',
        component: InboundRoutes,
        detail: InboundRoute,
        permissions: ['pbxs-inbound-routes']
      },
      {
        label: 'timeGroups',
        img: AccessAlarmOutlined,
        pathname: 'configuration/:pbxId/time-groups',
        alt: 'time groups',
        component: TimeGroups,
        detail: TimeGroup,
        permissions: ['pbxs-timegroups']
      },
      {
        label: 'timeConditions',
        img: HistoryToggleOffOutlined,
        pathname: 'configuration/:pbxId/time-conditions',
        alt: 'time conditions',
        component: TimeConditions,
        detail: TimeCondition,
        permissions: ['pbxs-timeconditions']
      },
      {
        label: 'ivr',
        img: AccountTreeOutlined,
        pathname: 'configuration/:pbxId/ivrs',
        alt: 'ivr',
        component: Ivrs,
        detail: Ivr,
        permissions: ['pbxs-ivrs']
      },
      {
        label: 'queues',
        img: ClearAllOutlined,
        pathname: 'configuration/:pbxId/queues',
        alt: 'queues',
        component: PbxQueues,
        detail: PbxQueue,
        permissions: ['pbxs-queues']
      },
      {
        label: 'recordings',
        img: AlbumOutlined,
        pathname: 'configuration/:pbxId/recordings',
        alt: 'recordings',
        component: Recordings,
        detail: Recording,
        permissions: ['pbxs-recordings']
      },
      {
        label: 'musicsOnHold',
        img: MusicNoteOutlined,
        pathname: 'configuration/:pbxId/musics-on-hold',
        alt: 'musics on hold',
        component: MusicsOnHold,
        detail: MusicOnHold,
        permissions: ['pbxs-musics-on-hold']
      },
      {
        label: 'announcements',
        img: CampaignOutlined,
        pathname: 'configuration/:pbxId/announcements',
        alt: 'announcements',
        component: Announcements,
        detail: Announcement,
        permissions: ['pbxs-announcements']
      },
      {
        label: 'blacklist',
        img: BlockRounded,
        pathname: 'configuration/:pbxId/blacklist',
        alt: 'blacklist',
        component: Blacklist,
        permissions: ['pbxs-blacklist']
      },
      {
        label: 'trunks',
        img: HubOutlined,
        pathname: 'configuration/:pbxId/trunks',
        alt: 'trunks',
        component: Trunks,
        detail: Trunk,
        permissions: ['pbxs-trunks']
      },
    ]
  }
]
