import { LanguageCode } from '../api/types'


export const languageMapping: Map<string, LanguageCode> = new Map([
  ['en', 'en_EN'],
  ['en-US', 'en_EN'],
  ['en-GB', 'en_EN'],
  ['en_EN', 'en_EN'],
  ['it', 'it_IT'],
  ['it-IT', 'it_IT'],
  ['it_IT', 'it_IT']
])

export const getMappedLanguage = (language: string): LanguageCode => {
  return languageMapping.get(language) || 'en_EN'
}