import React, {useCallback} from "react";
import {InstanceType} from "../SocialSummary";
import {Typography} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import {useSelector} from "react-redux";
import {getTranslation} from "../../../../../store/selectors";


interface Props{
  type: InstanceType
}



const HeaderType: React.FC<Props>  = ({type})=>{
  const translation = useSelector(getTranslation)
  const renderInstanceType = useCallback(()=>{
    switch (type){
      case InstanceType.Telegram: return 'telegram';
      case InstanceType.Whatsapp: return 'whatsapp';
      case InstanceType.Facebook: return 'facebook';
    }
  },[type])

  return <TypeReminder type={type}>
    {type === InstanceType.Whatsapp && <WhatsAppIcon fontSize={'large'}/>}
    {type === InstanceType.Telegram && <TelegramIcon fontSize={'large'}/>}
    {type === InstanceType.Facebook && <FacebookIcon fontSize={'large'}/>}
    <Typography variant={'h5'} sx={{marginRight: '10px'}}>
      {translation.pages.ADMINISTRATION_SOCIAL[renderInstanceType()]}
    </Typography>
  </TypeReminder>
}

const TypeReminder = styled(Paper)<{type: InstanceType}>`
    width: min-content;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;

    gap: 10px;
`

export default HeaderType