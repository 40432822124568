import React from "react";
import {Typography} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {useSelector} from "react-redux";
import {getTranslation} from "../../../../../store/selectors";


const TokenWarning: React.FC  = ()=>{
  const translation = useSelector(getTranslation)
  return(
  <ExpirationBadge>
    <WarningAmberIcon color={'warning'}/>
    <Typography variant={'h6'} sx={{whiteSpace: 'nowrap'}}>
      {translation.pages.ADMINISTRATION_SOCIAL.accessTokenExpiring}
    </Typography>
  </ExpirationBadge>)
}

const ExpirationBadge = styled(Paper)`
    width: min-content;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    gap: 10px;
    padding-right: 16px;
    min-width: 100px;
`

export default TokenWarning