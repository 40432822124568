import React, {useCallback, useEffect, useMemo} from "react";
import {useMatch} from "react-router-dom";
import {InstanceType, SocialInstanceInfo} from "./SocialSummary";
import {Department} from "../../../../api/types";
import FacebookDetails from "./components/details/FacebookDetails";
import TelegramDetails from "./components/details/TelegramDetails";
import WhatsappDetails from "./components/details/WhatsappDetails";
import NewSocialPickerPage from "./components/NewSocialPickerPage";
import {useRest} from "../../../../hooks";
import useCoordinatorRest from "../../../../hooks/useCoordinatorRest";
import {usePageLoadingState} from '../../../../hooks/usePageLoadingState'
import logger from '../../../../helpers/logger'

export const SocialDetail: React.FC = ()=>{
  const match = useMatch('administration/social/:type/:id')
  const id = match?.params.id
  const typeParam = match?.params?.type

  const isNew = useMemo(()=> id === 'new', [id])

  const { results: departments, isLoading: isDepartmentsLoading} = useRest<Department>('departments', {lazy: false})
  const { result: social, getById, loading: isSocialsLoading } = useCoordinatorRest<SocialInstanceInfo>('socials', {lazy: false})

  const loading = useMemo(() => [isSocialsLoading, isDepartmentsLoading], [isSocialsLoading, isDepartmentsLoading])
  usePageLoadingState(loading)

  useEffect(() => {
    if(id === 'new')return
    getById({id}).catch(logger.error)
  },[id, getById])

  const renderDetailComponent = useCallback(()=>{
    switch (typeParam) {
      case InstanceType.Facebook: return <FacebookDetails id={id} departments={departments} instance={social}/>;
      case InstanceType.Telegram: return <TelegramDetails id={id} departments={departments} instance={social}/>;
      case InstanceType.Whatsapp: return <WhatsappDetails id={id} departments={departments} instance={social}/>;
      default: {
        return isNew && <NewSocialPickerPage/>
      }
    }
  },[social, departments, id, isNew, typeParam])

  return renderDetailComponent()
}

