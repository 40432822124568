import {useEffect} from 'react'
import {useLoader} from './useLoader'

export function usePageLoadingState(dependencies: boolean[]) {
  const {setPageLoading} = useLoader()

  useEffect(() => {
    // Verifica se almeno uno degli stati di caricamento è true
    const isAnyLoading = dependencies.some(dep => dep)

    // Imposta lo stato del loader
    setPageLoading(isAnyLoading)

    // Cleanup solo quando il componente viene smontato
    return () => {
      if (dependencies.every(dep => !dep)) {
        setPageLoading(false)
      }
    }
  }, [dependencies, setPageLoading])
}




