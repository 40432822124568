import {usePrevious} from '../../../hooks'
import {Box, TableCell, TableSortLabel, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import classNames from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import Draggable, {DraggableEventHandler} from 'react-draggable'
import {useLocation, useNavigate} from 'react-router-dom'
import {calcColumnWidth} from './utils'
import styled from 'styled-components'

function useCellRenderer<RowData>(props: UseCellRendererParams<RowData>) {
  const {
    idField,
    cssId,
    recomputeGridSize,
    columns,
    width,
    includeHeaders = true,
    data,
    columnWidth,
    isCellSelected,
    isCellDisabled,
    classes = {},
    orderBy,
    orderDirection,
    onHeaderClick,
    onCellClick,
    onCellDoubleClick,
    onCellContextMenu,
    resizable,
    cellProps: defaultCellProps,
    sort,
    isDataDeleted,
    isDataToBeDeleted,
    enableDoubleClick,
    alternativePathDoubleClick
  } = props

  const [hovered, setHovered] = useState<HTMLCollectionOf<HTMLElement> | null>(null)
  const [widths, setWidths] = useState(calculateWidths({ resizable, columns }))
  const [initialWidths] = useState(calculateWidths({ resizable, columns }))

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const theme = useTheme()

  const oldHovered = usePrevious(hovered)

  useEffect(() => {
    if (!oldHovered) return
    for (let i = 0; i < oldHovered.length; i++) {
      oldHovered[i].style.background = ''
    }
    if (!hovered) return
    for (let i = 0; i < hovered.length; i++) {
      hovered[i].style.background = theme.palette.action.hover
    }
  }, [oldHovered, hovered, theme.palette.action.hover])

  useEffect(() => {
    recomputeGridSize()
  }, [recomputeGridSize, widths])

  const resizableColumnWidths = useCallback((index: number, columns: any, tableWidth: number) => widths[columns[index].name] * tableWidth, [
    widths
  ])

  const getColumnWidth = useCallback(
    ({ index }: { index: number }) =>
      typeof columnWidth === 'function'
        ? columnWidth({ index, columns, width })
        : resizable
          ? resizableColumnWidths(index, columns, width)
          : calcColumnWidth(index, columns, width - 20),
    [columnWidth, resizable, columns, width, resizableColumnWidths]
  )


  const resizeRow = useCallback(
    ({ dataKey, deltaX }: { dataKey: string, deltaX: number }) => {
      setWidths((prev) => {
        const delta = deltaX / width
        const index = columns.findIndex((c) => c.name === dataKey)
        const nextDataKey = columns[index + 1].name
        const initialColumnWidth = prev[dataKey as any]
        const initialNextColumnWidth = prev[nextDataKey]
        const minWidth = Math.min(100 / width, initialWidths[nextDataKey])

        let currentColumnWidth: number
        let nextColumnWidth: number
        const columnWidthSum = initialNextColumnWidth + initialColumnWidth
        const finalNextColumnWidth = initialNextColumnWidth - delta
        const finalCurrentColumnWidth = initialColumnWidth + delta

        //verso sx
        if (delta < 0) {
          currentColumnWidth = finalNextColumnWidth <= (columnWidthSum - minWidth) ? finalCurrentColumnWidth : minWidth
          nextColumnWidth = finalNextColumnWidth <= (columnWidthSum - minWidth) ? finalNextColumnWidth : columnWidthSum - minWidth

          // verso dx
        } else {
          currentColumnWidth = finalNextColumnWidth >= minWidth ? finalCurrentColumnWidth : columnWidthSum - minWidth
          nextColumnWidth = finalNextColumnWidth >= minWidth ? finalNextColumnWidth : minWidth
        }

        return {
          ...prev,
          [dataKey]: currentColumnWidth,
          [nextDataKey]: nextColumnWidth
        }
      })
    },
    [setWidths, columns, width, initialWidths]
  )

  const handleDrag = useCallback<(dataKey: any) => DraggableEventHandler>(
    (dataKey) => (event, { deltaX }) =>
      resizeRow({
        dataKey,
        deltaX
      }),
    [resizeRow]
  )

  const handleMouse = useCallback(
    (hoveredColumn: any, hoveredRowData: RowData | null) => () => {
      if (!hoveredRowData) {
        setHovered(null)
        return
      }
      setHovered(
        document.getElementsByClassName(`row-${cssId}-${hoveredRowData[idField]}`) as HTMLCollectionOf<HTMLElement>
      )
    },
    [idField, cssId]
  )

  const cellRenderer = ({ columnIndex, rowIndex, key, style }: CellData) => {
    const column = columns[columnIndex]
    const isHeader = includeHeaders && rowIndex === 0
    const headerOffset = includeHeaders ? 1 : 0
    const rowData = (data && data[rowIndex - headerOffset]) || {}

    const isSelected = isCellSelected && isCellSelected(column, rowData)
    const isDisabled = isCellDisabled && isCellDisabled(column, rowData)

    const resolveCellProps = (cellProps: any) =>
      typeof cellProps === 'function' ? cellProps(column, rowData) : cellProps
    const { style: cellStyle, ...cellProps }: any = {
      ...resolveCellProps(defaultCellProps),
      ...resolveCellProps(column.cellProps)
    }

    const contents = (
      <div
        className={classes.cellContents}
        style={{ display: 'flex', width: '100%', justifyContent: 'space-between', overflow: 'hidden' }}>
        <span
          style={{
            placeContent: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingRight: 8
          }}>
          {isHeader ? (
            <Typography component={Box}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                whiteSpace: 'pre-line',
                width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'
              }}>
              {column.header != null ? column.header : column.name}
            </Typography>
          ) : column.cell ? (
            column.cell(rowData)
          ) : (
            rowData[column.name]
          )}
        </span>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', alignContent: 'end' }}>
          <span style={{}}>
            {isHeader && resizable && columnIndex < columns.length - 1 && (
              <Draggable
                axis="x"
                defaultClassName={classes.dragHandle}
                defaultClassNameDragging={classes.DragHandleActive}
                onDrag={handleDrag(column.name)}
                position={{ x: 0, y: 0 }}>

                <span
                  className={classes.DragHandleIcon}
                  style={{
                    color: theme.palette.text.primary,
                    marginRight: '3px'
                  }}> ⋮ </span>
              </Draggable>
            )}
          </span>
        </div >
      </div>
    )

    const hasCellClick = !isHeader && onCellClick
    const hasCellDoubleClick = !isHeader && onCellDoubleClick
    const hasCellContextMenu = !isHeader && onCellContextMenu
    const isClickable = hasCellClick || hasCellDoubleClick || hasCellContextMenu || column.onClick

    const className = classNames(classes.cell, {
      [classes.cellClickable]: isClickable,
      [classes.cellSelected]: isSelected,
      [classes.cellDisabled]: isDisabled,
      [classes.cellHeader]: isHeader,
      [classes.cellInLastColumn]: columnIndex === columns.length - 1,
      [classes.cellInLastRow]: !isHeader && rowIndex === (data ? data.length : 0)
    })

    const rowClassName = !isHeader && `row-${cssId}-${rowData[idField]}`

    return (
      <TableCell
        component="div"
        padding="none"
        className={`${className} ${rowClassName}`}
        key={key}
        onMouseEnter={handleMouse(column, rowData)}
        onMouseLeave={handleMouse(null, null)}
        onDoubleClick={() => !isHeader && enableDoubleClick && (rowData[idField] && alternativePathDoubleClick ? navigate(alternativePathDoubleClick + `/${rowData[idField]}`, { state: { previousPath: pathname } }) : navigate(pathname + `/${rowData[idField]}`, { state: { previousPath: pathname } }))}
        sx={{
          ...style,
          ...cellStyle,
          ...(!isHeader && column.style),
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'flex-start',
          cursor: !isHeader && (enableDoubleClick || !!onCellClick) ? 'pointer' : 'inherit',
          color:
            isDataDeleted && isDataToBeDeleted && (isDataDeleted(rowData) || isDataToBeDeleted(rowData))
              ? 'action.disabled'
              : 'text.primary'
        }}
        {...(hasCellClick && {
          onClick: (event) => onCellClick(event, { column, rowData, data })
        })} // Can be overridden by cellProps.onClick on column definition
        {...(hasCellDoubleClick && {
          onDoubleClick: (event) => onCellDoubleClick(event, { column, rowData, data })
        })} // Can be overridden by cellProps.onDoubleClick on column definition
        {...(hasCellContextMenu && {
          onContextMenu: (event) => onCellContextMenu(event, { column, rowData, data })
        })} // Can be overridden by cellProps.onContextMenu on column definition
        {...cellProps}>
        {isHeader &&
          column.onHeaderClick !== false &&
          (column.onHeaderClick || onHeaderClick || (column.sortable && sort)) ? (
          <HeaderContainer style={{ display: 'flex', width: '100%', alignItems: 'center' }} onClick={(event) =>
            column.onHeaderClick
              ? column.onHeaderClick(event, { column, orderBy, orderDirection })
              : onHeaderClick
                ? onHeaderClick(event, { column, orderBy, orderDirection })
                : sort && defaultHeaderClickForSort(sort, { column, orderBy, orderDirection })
          }>
            <StyledTableSortLabel
              active={!!orderBy && (orderBy === column.name || orderBy === column.orderBy) && rowIndex === 0}
              direction={orderDirection}
            />
            {contents}
          </HeaderContainer>
        ) : isHeader && column.resizable ? (
          <React.Fragment>
            {contents}
            <Draggable
              axis="x"
              defaultClassName="DragHandle"
              defaultClassNameDragging="DragHandleActive"
              onDrag={handleDrag(column.name)}
              position={{ x: 0, y: 0 }}>
              <span className="DragHandleIcon" style={{ color: theme.palette.text.primary, marginRight: '3px' }}> ⋮ </span>
            </Draggable>
          </React.Fragment>
        ) : (
          contents
        )
        }
      </TableCell >
    )
  }

  return { cellRenderer, columnWidth: getColumnWidth }
}

export default useCellRenderer
//region Style
const HeaderContainer = styled.div`
    // display: flex; 
    // width: 100%;
    // align-items: center;
    // :hover {
    //     cursor: pointer;
    // }
`

const StyledTableSortLabel = styled(TableSortLabel)`
    :first-child {
        height: 1rem;
        margin: 0;
     
    }
`

//endregion


//region Types

interface UseCellRendererParams<RowData> {
  idField: keyof RowData
  cssId?: string
  recomputeGridSize: Function
  data: any[]
  columns: any[]
  width: number
  classes?: { [key: string]: any }
  includeHeaders?: boolean
  columnWidth?: number | Function
  isCellSelected?: Function
  isCellDisabled?: Function
  orderBy?: string
  orderDirection?: 'asc' | 'desc'
  onHeaderClick?: Function
  onCellClick?: Function
  onCellDoubleClick?: Function
  onCellContextMenu?: Function
  resizable?: boolean
  cellProps?: Object | Function
  sort?: (name: string, direction: 'asc' | 'desc') => void
  isDataDeleted?: (row: RowData) => boolean
  isDataToBeDeleted?: (row: RowData) => boolean
  enableDoubleClick?: boolean
  alternativePathDoubleClick?: string
}

interface CellData {
  columnIndex: number
  rowIndex: number
  key: string
  style: Object
}

//endregion

//region Helpers
interface TableColumnWidth {
  name: string;
  width?: number | string;
}

const calculateWidths = ({ resizable = false, columns, width = 1000 }: {
  resizable?: boolean;
  columns: TableColumnWidth[];
  width?: number;
}): { [key: string]: number } => {
  const widths: { [key: string]: number } = {};

  if (!resizable) {
    columns.forEach(column => {
      widths[column.name] = 100;
    });
    return widths;
  }

  // Calcolo larghezza delle colonne
  const numericColumns = columns.filter(col => typeof col.width === 'number');
  const stringColumns = columns.filter(col => typeof col.width === 'string');
  const noWidthColumns = columns.filter(col => !col.width);

  const totalFixedWidth = numericColumns.reduce((sum, col) => sum + (col.width as number || 0), 0);

  // Calcoliamo lo spazio per le width in percentuale (stringhe)
  const stringWidthsTotal = stringColumns.reduce((sum, col) => {
    const percentage = parseInt(col.width as string);
    return sum + (isNaN(percentage) ? 0 : percentage / 100);
  }, 0);

  // Calcoliamo lo spazio rimanente per le colonne senza width
  const remainingWidth = 1 - (totalFixedWidth / width) - stringWidthsTotal;
  const dynamicWidth = noWidthColumns.length ? remainingWidth / noWidthColumns.length : 0;


  columns.forEach(column => {
    if (typeof column.width === 'number') {
      widths[column.name] = column.width / width;
    } else if (typeof column.width === 'string') {
      const percentage = parseInt(column.width);
      widths[column.name] = isNaN(percentage) ? dynamicWidth : percentage / 100;
    } else {
      widths[column.name] = dynamicWidth;
    }
  });

  return widths;
};



const defaultHeaderClickForSort = (
  sort: (name: string, direction: 'asc' | 'desc') => void,
  { column, orderBy, orderDirection }: { column: { name: string }; orderBy?: string; orderDirection?: 'asc' | 'desc' }
) => {
  if (column.name === orderBy) {
    const direction = orderDirection ? (orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    sort(column.name, direction)
  } else {
    sort(column.name, 'desc')
  }
}
//endregion
