import {Box, FormControlLabel, Switch} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableFooter from '@mui/material/TableFooter'
import TablePagination, {TablePaginationProps} from '@mui/material/TablePagination'
import {makeStyles} from '@mui/styles'
import {getTranslation} from '../../../store/selectors'
import React, {SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid'
import useCellRenderer from './useCellRenderer'

const FOOTER_BORDER_HEIGHT = 1

export function VirtualizedTable<RowData = RowDataDefault>(props: MuiTableProps<RowData>) {
  const {
    idField,
    cssId,
    data,
    columns,
    width,
    height,
    maxHeight = null,
    pagination,
    fitHeightToRows,
    fixedRowCount = 0,
    fixedColumnCount = 0,
    rowHeight = 48,
    style,
    columnWidth,
    includeHeaders = false,
    isCellSelected,
    isCellDisabled,
    classes: Classes,
    orderBy,
    orderDirection,
    onHeaderClick,
    onCellClick,
    onCellDoubleClick,
    onCellContextMenu,
    resizable,
    cellProps,
    sort,
    onDenseChange,
    isDataDeleted,
    isDataToBeDeleted,
    enableDoubleClick = true,
    alternativePathDoubleClick,
    ...other
  } = props

  const translation = useSelector(getTranslation)

  const [containerHeight, setContainerHeight] = useState(0)
  const [multiGridHeight, setMultiGridHeight] = useState(0)
  const [paginationHeight, setPaginationHeight] = useState(0)
  const [dense, _setDense] = useState(false)
  const classes = useStyles({ classes: Classes })
  const theme = useTheme()

  const multiGrid = useRef<MultiGrid>(null)

  const recomputeGridSize = useCallback(() => multiGrid.current && multiGrid.current.recomputeGridSize(), [multiGrid])

  useEffect(() => {
    recomputeGridSize()
  }, [columns, data, height, width, recomputeGridSize])

  /** calcola le altezze giuste in base alla presenza della paginazione o meno */
  useEffect(() => {
    let calculatedHeight = 0
    if (height) {
      // Se viene passata la height dalle prop, va fissata a quel valore
      calculatedHeight = height
    } else if (pagination && pagination.rowsPerPage && !fitHeightToRows) {
      // altrimenti si calcola rispetto alle righe presenti nel caso di paginazione
      const rowCount = pagination.rowsPerPage + (fixedRowCount ? fixedRowCount : includeHeaders ? 1 : 0)
      calculatedHeight = rowCount * rowHeight
    } else if (Array.isArray(data)) {
      // altrimenti si calcola da tutti i data
      const rowCount = data.length + (fixedRowCount ? fixedRowCount : includeHeaders ? 1 : 0)
      calculatedHeight = rowCount * rowHeight
    }

    // l'altezza del footer lo calcoliamo grazie al tema
    const _paginationHeight = pagination ? (theme?.mixins.toolbar.minHeight as number) + FOOTER_BORDER_HEIGHT : 0

    // il container deve essere la calculatedHeight o al massimo la maxHeight
    const _containerHeight = typeof maxHeight === 'number' ? Math.min(calculatedHeight, maxHeight) : calculatedHeight

    // l'altezza della griglia dipende dalla presenza della paginazione
    const _multiGridHeight = _containerHeight - _paginationHeight

    setContainerHeight(_containerHeight)
    setMultiGridHeight(_multiGridHeight)
    setPaginationHeight(_paginationHeight)
  }, [
    height,
    data,
    pagination,
    fitHeightToRows,
    fixedRowCount,
    includeHeaders,
    maxHeight,
    rowHeight,
    theme?.mixins.toolbar.minHeight
  ])

  useEffect(() => {
    recomputeGridSize()
  }, [dense, recomputeGridSize])

  const denseChange = () => {
    const newDense = !dense
    onDenseChange && onDenseChange(newDense)
    _setDense(newDense)
  }

  return (
    <Box sx={{ position: 'relative', width, height: containerHeight }}>
      <Table component="div" style={{ width, height: containerHeight, ...style }} className={classes.table} {...other}>
        {/*@ts-ignore*/}
        <MultiGrid
          {...useCellRenderer<RowData>({
            idField,
            cssId,
            recomputeGridSize,
            data,
            columns,
            width,
            classes,
            includeHeaders,
            columnWidth,
            isCellSelected,
            isCellDisabled,
            orderBy,
            orderDirection,
            onHeaderClick,
            onCellClick,
            onCellDoubleClick,
            onCellContextMenu,
            resizable,
            cellProps,
            sort,
            isDataDeleted,
            isDataToBeDeleted,
            enableDoubleClick,
            alternativePathDoubleClick
          })}
          ref={multiGrid}
          width={width}
          columnCount={Array.isArray(columns) ? columns.length : 0}
          fixedColumnCount={fixedColumnCount}
          enableFixedColumnScroll={fixedColumnCount > 0}
          height={multiGridHeight}
          rowHeight={rowHeight - (dense ? 10 : 0)}
          rowCount={Array.isArray(data) ? data.length + (includeHeaders ? 1 : 0) : 0}
          fixedRowCount={fixedRowCount}
          enableFixedRowScroll={fixedRowCount > 0}
          classNameTopLeftGrid={'topLeftGrid'}
          classNameTopRightGrid={'topRightGrid'}
          classNameBottomLeftGrid={'bottomLeftGrid'}
          classNameBottomRightGrid={'bottomRightGrid'}
        />
        {pagination && (
          <TableFooter
            component="div"
            className={classes.footer}
            style={{
              height: paginationHeight,
              backgroundColor: 'transparent',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}>
            <FormControlLabel
              control={<Switch checked={dense} onChange={denseChange} />}
              label={translation.input.densePadding}
            />
            <TablePagination
              component="div"
              {...pagination}
              labelRowsPerPage={translation.input.rowsPerPage}
              sx={{ minHeight: '50%', border: 'none' }}
            />
          </TableFooter>
        )}
      </Table>
    </Box>
  )
}
//region Types

export interface MuiTableProps<RowData> {
  data: RowData[]
  columns: ColumnData<RowData>[]
  width: number
  height?: number
  maxHeight?: number | null
  pagination?: TablePaginationProps
  fitHeightToRows?: boolean
  fixedRowCount?: number
  fixedColumnCount?: number
  rowHeight?: number
  columnWidth?: number | Function
  includeHeaders?: boolean
  orderBy?: string
  orderDirection?: 'asc' | 'desc'
  onHeaderClick?: (
    e: SyntheticEvent,
    payload: { column: ColumnData<RowData>; orderBy?: string; orderDirection?: 'asc' | 'desc' }
  ) => void
  onCellClick?: (
    event: SyntheticEvent,
    { column, rowData, data }: { column: ColumnData<RowData>; rowData: RowData; data: RowData[] }
  ) => void
  onCellDoubleClick?: Function
  onCellContextMenu?: Function
  noPointer?: boolean
  resizable?: boolean
  isCellSelected?: (column: ColumnData<RowData>, rowData: RowData) => boolean
  isCellDisabled?: (column: ColumnData<RowData>, rowData: RowData) => boolean
  classes?: { [key: string]: any }
  cellProps?: Object | Function
  style?: React.CSSProperties
  sort?: (name: string, direction: 'asc' | 'desc') => void
  idField: keyof RowData
  cssId?: string
  onDenseChange?: (dense: boolean) => any
  isDataDeleted?: (row: RowData) => boolean
  isDataToBeDeleted?: (row: RowData) => boolean
  enableDoubleClick?: boolean
  alternativePathDoubleClick?: string
}

export interface ColumnData<RowData> {
  name: string
  header: any
  cell?: (rowData: RowData) => React.ReactNode
  sortable?: boolean
  width?: number | string
  style?: Object
}

export interface RowDataDefault {
  id: number
  [key: string]: any
}

//endregion

//region Style

const useStyles = makeStyles((theme: any) => ({
  table: {
    boxSizing: 'border-box',

    '& .topLeftGrid': {
      backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 800 : 200],
      borderBottom: `2px solid ${theme.palette.divider}`,
      borderRight: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    },

    '& .topRightGrid': {
      borderBottom: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    },

    '& .bottomLeftGrid': {
      backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 800 : 200],
      borderRight: `2px solid ${theme.palette.divider}`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(13),

      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    },

    '& .bottomRightGrid': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(13),
      outline: 'none', // See: https://github.com/bvaughn/react-virtualized/issues/381,
      // Hide scrollbars on Chrome/Safari/IE
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    }
  },
  cell: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  cellClickable: {
    cursor: 'pointer'
  },
  cellSelected: {
    backgroundColor: theme.palette.action.selected
  },
  cellDisabled: {
    opacity: 0.5
  },
  cellContents: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cellHeader: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary
  },
  cellInLastColumn: {
    paddingRight: theme.spacing(3)
  },
  cellInLastRow: {
    borderBottom: 'none'
  },
  footer: {
    borderTop: `${FOOTER_BORDER_HEIGHT}px solid ${theme.palette.divider}`
  },
  dragHandle: {
    flex: '0 0 16px',
    zIndex: 2,
    cursor: 'col-resize',
    color: '#0085ff'
  },
  DragHandleActive: {
    color: '#0b6fcc',
    zIndex: 3
  },
  DragHandleIcon: {
    flex: '0 0 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

//endregion
