import { Typography } from '@mui/material'
import { getLanguage } from '../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Pause, Status } from '../../api/types'
import useElapsedTime from '../../hooks/useElapsedTime'
import { StatusCircle } from './StatusCircle'

type Props = {
  status?: Pause | Status
  showTimer: boolean
}

export const StatusReport = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { status, showTimer } = props

  const language = useSelector(getLanguage)
  const { elapsedTimeString } = useElapsedTime(status?.lastchange)

  return (
    <Container ref={ref}>
      <StatusContainer>
        <StatusCircle status={status} size={'0.8rem'} style={{ margin: '0.2rem', marginLeft: 0 }} />
        <Typography variant={'body1'} sx={{ fontSize: '0.8rem' }} align={'left'}>
          {status?.translations[language]}
        </Typography>
      </StatusContainer>
      {showTimer && (
        <Typography variant={'body2'} sx={{ fontSize: '0.7rem' }} align={'left'}>
          {elapsedTimeString}
        </Typography>
      )}
    </Container>
  )
})

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: left;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  display: grid;
  justify-items: left;
  align-items: center;
  width: 100%;
  height: 100%;
`
