import React from "react";
import styled from "styled-components";
import {Chip, Fab} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import KeyIcon from "@mui/icons-material/Key";
import {SocialInstanceInfo} from "../SocialSummary";
import { useSelector } from 'react-redux'
import {getTranslation} from "../../../../../store/selectors";

enum StatusDotType {
  ON = 'on',
  OFF = 'off',
  WARNING ='warning',
}

interface Props{
  instance?: SocialInstanceInfo
  withoutAuth?: boolean
  setModalOpen?: (value: boolean)=> void
  status: boolean
}

const StatusInfo: React.FC<Props> = ({ instance, withoutAuth = false, status , setModalOpen}) => {
  const translation = useSelector(getTranslation)
  const theme = useTheme()
  return !!instance ? (
    <Container>
      <Chip variant={'filled'} label={<Info id={'info'}><StatusDot type={status ? StatusDotType.ON : StatusDotType.OFF}/><strong>{translation.pages.ADMINISTRATION_SOCIAL.active}</strong></Info>}  />
      {!withoutAuth && <Chip variant={'filled'} label={<Info><StatusDot type={status ? StatusDotType.ON : StatusDotType.OFF}/><strong>{translation.pages.ADMINISTRATION_SOCIAL.authorized}</strong></Info>}/>}
      {setModalOpen && !withoutAuth && <Fab size="small" sx={{mx: 1, bgcolor: theme.palette.mode === 'dark' ? 'grey.500' : 'grey.300'}}
            onClick={() => setModalOpen(true)}>
        <KeyIcon color="action"/>
      </Fab>}
    </Container>) : (<></>)


}

export default StatusInfo



const Container = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-right: 3rem ;
    flex-direction: column;
`

const Info = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    gap: 1rem;
`


const StatusDot = styled.div<{type: StatusDotType, theme: any}>`
    width: 1.6rem;
    height: 1.6rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${({type, theme})=>{
      switch (type) {
          case StatusDotType.OFF : return theme.palette.error.main
          case StatusDotType.ON : return theme.palette.success.main
          case StatusDotType.WARNING : return theme.palette.warning.main
          
      }
    }}

`

