import Template from '../src/components/template'
import {history, IMenuItem, menuItems} from '../src/constants'
import {logger} from '../src/helpers'
import {createTheme, CssBaseline} from '@mui/material'
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import {StylesProvider} from '@mui/styles'
import React, {useMemo, useState} from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {Route, Routes} from 'react-router-dom'
import 'react-virtualized/styles.css'
import styled, {DefaultTheme, ThemeProvider} from 'styled-components'
import pkg from '../package.json'
import './App.css'
import withAuthentication from './components/hoc/withAuthentication'
import AuthManager from './components/managers/AuthManager'
import ClientOnly from './components/pages/ClientOnly'
import Login from './components/pages/login/Login'
import OTP from './components/pages/login/OTP'
import NoRole from './components/pages/NoRole'
import NotFound from './components/pages/NotFound'
import Notifications from './components/pages/Notifications'
import Wizard from './components/pages/wizard/Wizard'
import usePermissions, {HasPermissionToFunction} from './hooks/usePermissions'
import {CustomRouter} from './components/CustomRouter'
import {renderStatsRoutes} from './components/pages/tools/statistics/Routes'
import AppStateManager from './components/managers/AppStateManager'
import UnauthenticatedAppStateManager from './components/managers/UnauthenticatedAppStateManager'
import {waitForWebSocket} from './components/hoc/waitForWebSocket'
import {LoaderProvider} from './components/common/LoaderStore'

logger.oc('•', `OpenCommunication Dashboard V.${pkg.version}`)
window.occlient?.setLogLevel('error')

const App = () => {
  const {userCan: userCanStats} = usePermissions('stats')
  const {hasPermissionTo} = usePermissions()
  const [theme, setTheme] = useState<DefaultTheme>(createTheme())

  /** elimina dalle rotte quelle per cui l'utente loggato non ha permessi */
  const filteredMenu = useMemo(
    () => menuItems.map(checkPermissionsRecursively(hasPermissionTo)).filter((x) => !!x) as IMenuItem[],
    [hasPermissionTo]
  )

  const routes = useMemo(() => {
    const routes = []
    for (let item of filteredMenu) {
      item.subMenu && routes.push(...renderRoute(item.subMenu))
    }
    return routes
  }, [filteredMenu])

  return (
    <AppContainer>
      <CssBaseline/>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <LoaderProvider>
              <CustomRouter history={history}>
                <AuthManager/>
                <UnauthenticatedAppStateManager setTheme={setTheme}/>
                <AppStateManager/>
                <Routes>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/login/otp" element={<OTP/>}/>
                  <Route path="/wizard" element={<Wizard/>}/>
                  <Route path="/" element={<Template menuItems={filteredMenu} setTheme={setTheme}/>}>
                    <Route path="notifications" element={<Notifications/>}/>
                    <Route path="norole" element={<NoRole/>}/>
                    <Route path="onlyclient" element={<ClientOnly/>}/>
                    {routes}
                    {userCanStats.view && renderStatsRoutes()}
                    <Route path="*" element={<NotFound/>}/>
                  </Route>
                </Routes>
              </CustomRouter>
            </LoaderProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </AppContainer>
  )
}

export default App

//region Helpers
const renderRoute = (items: IMenuItem[]): React.ReactElement[] => {
  const results: React.ReactElement[] = []
  if (!items || !items.length) {
    return []
  }
  for (let item of items) {
    if (item.subMenu) {
      results.push(...renderRoute(item.subMenu))
    } else {
      if (item.pathname) {
        if (item.component) {
          const AuthComponent = withAuthentication(waitForWebSocket(item.component))
          results.push(
            <Route key={item.pathname} path={item.pathname} element={<AuthComponent/>}/>
          )
        }
        if (item.detail) {
          const AuthComponentDetail = withAuthentication(waitForWebSocket(item.detail))
          const itemPath = item?.detailPathName || item.pathname
          results.push(
            <Route
              key={item.pathname + '/:id'}
              path={itemPath + '/:id'}
              element={<AuthComponentDetail/>}
            />
          )
        }
      }
    }
  }
  return results
}

const checkPermissionsRecursively = (hasPermissionTo: HasPermissionToFunction) => (
  item: IMenuItem
): IMenuItem | null => {
  const newItem = {...item}
  if (item.permissions) {
    if (item.permissions.every((p) => hasPermissionTo('view', p))) {
      if (newItem.subMenu)
        newItem.subMenu = newItem.subMenu
          .map(checkPermissionsRecursively(hasPermissionTo))
          .filter((x) => !!x) as IMenuItem[]
      return newItem
    } else {
      return null
    }
  } else {
    if (newItem.subMenu) {
      newItem.subMenu = newItem.subMenu
        .map(checkPermissionsRecursively(hasPermissionTo))
        .filter((x) => !!x) as IMenuItem[]
      return newItem.subMenu.length ? newItem : null
    } else {
      return newItem
    }
  }
}
//endregion

//region Style
const AppContainer = styled.div`
    background: var(--page-background);
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
    max-width: 100%;
    box-sizing: border-box;
`
//endregion