import {Pbx} from '../../api/types'
import * as t from './types'
import {HelpPage, WarningItem} from './types'
import socketManager from '../../api/socket'
import log from '../../helpers/logger'
import {LoginPayload} from '../login/types'


/**
 * Insieme al titolo si può impostare il breadcrumb, un array di stringhe. L'array ha un path dove necessaro nella stessa
 * posizione dove si desidera premere per andarci nel titolo dopo lo split su '/'
 * es:  title: Strumenti / Statistiche / Nome statistica
 * breadcrumb: ['', '/tools/stats'] => porta a /tools/stats premendo su Statistiche
 * Di default se si passa una stringa viene azzerato il breadcrumb per eliminare link indesiderati settati da altre pagine
 * @param payload
 */
export const setTitle = (
  payload: { title: string; breadcrumb?: string[] } | string
): t.ApplicationStateActionTypes => {
  return ({
    type: t.SET_TITLE,
    payload: typeof payload === 'string'
      ? {title: payload, breadcrumb: []}
      : {title: payload.title, breadcrumb: payload.breadcrumb || []}
  })
}

export const setBreadCrumb = (payload: string[]): t.ApplicationStateActionTypes => ({type: t.SET_BREADCRUMB, payload})

export const setHelpPage = (payload: HelpPage): t.ApplicationStateActionTypes => ({type: t.SET_HELP_PAGE, payload})
export const setOCClientRef = (payload: Window | null): t.ApplicationStateActionTypes => ({type: t.SET_OCCLIENT_REF, payload})

export const setPath = (payload: string): t.ApplicationStateActionTypes => {
  return {type: t.SET_PATH, payload}
}

export const setTheme = (payload: t.Theme): t.ApplicationStateActionTypes => {
  sessionStorage.setItem('theme', payload)
  return {type: t.SET_THEME, payload}
}

export const setPbxs = (payload: Pbx[]) => ({
  type: t.SET_PBXS,
  payload
})

export const selectPbx = (payload: number) => ({
  type: t.SELECT_PBX,
  payload
})

export const notify = (payload: Omit<t.RestNotification, 'open'>): t.ApplicationStateActionTypes => {
  return {type: t.NOTIFY, payload: {...payload, open: true}}
}

export const resetNotification = (): t.ApplicationStateActionTypes => {
  return {type: t.RESET_NOTIFICATION}
}

export const setSelectedTableRows = (payload: any[]) => ({
  type: t.SET_SELECTED_TABLE_ROWS,
  payload
})

export const setWarnings = (payload: WarningItem[]) => ({
  type: t.SET_WARNINGS,
  payload
})

export const setLoading = (payload: boolean) => ({
  type: t.SET_LOADING,
  payload
})

export const setButtonLoading = (payload: boolean) => ({
  type: t.SET_BUTTON_LOADING,
  payload
})

export const connectToSocket = (loginPayload: LoginPayload) => {
  if (!socketManager.socket.connected) {
    !!loginPayload.user.actions?.view?.find((action) => action === 'dashboard') && socketManager
      .connect()
      .then(() => {
        log.debug('Socket connected')
      })
      .catch((e) => log.error(e))
  }
}

export const disconnectFromSocket = () => {
  socketManager
    .disconnect()
    .then(() => log.debug('Socket disconnected'))
    .catch((e) => log.error(e))
}
