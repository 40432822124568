import imgBar from '../../../../../images/stats/bckstats-bar.png'
import imgDonut from '../../../../../images/stats/bckstats-doughnut.png'
import imgLine from '../../../../../images/stats/bckstats-line.png'
import imgPie from '../../../../../images/stats/bckstats-pie.png'
import imgTable from '../../../../../images/stats/bckstats-table.png'
import {StarBorderRounded, StarRounded} from '@mui/icons-material'
import {Box, Card, IconButton, Tooltip, Typography} from '@mui/material'
import {getStatsFavourites, getTranslation} from '../../../../../store/selectors'
import React, {startTransition, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {ChartType, Entities, StatsFavourite} from '../../../../../store/stats/types'
import {useRest} from '../../../../../hooks'
import {logger} from '../../../../../helpers'
import {setStatsFavourites} from '../../../../../store/stats/actions'

const StatCard: React.FC<Props> = ({ type, title, description, entity, path }) => {
  const translation = useSelector(getTranslation)
  const favourites = useSelector(getStatsFavourites)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { remove, post } = useRest<StatsFavourite>('my-stats-favorites')

  const backgroundImage = useMemo(() => {
    const bg = mapTypeToImg[type]
    return bg || imgLine
  }, [type])

  const isFavourite = useMemo(() => {
    return favourites.find((f: any) => f.path === path)
  }, [favourites, path])

  const removeStatsFavourite = async (favouritePath: string) => {
    remove(favouritePath, {withoutNotification: true})
      .then(res =>  dispatch(setStatsFavourites(res.payload)))
      .catch(e => logger.error(e))
  }

  const addStatsFavourite = async (favourite: StatsFavourite) => {
    post(favourite, {withoutNotification: true})
      .then(res => dispatch(setStatsFavourites(res.payload)))
      .catch(e => logger.error(e))
  }

  return (
    <Container
      bg={backgroundImage}
      disabled={path.includes('...')}
      onClick={() => {
        startTransition(() => {
          !path.includes('...') && navigate(path)
        })
      }}
      sx={{ bgcolor: path.includes('...') ? 'lightgray' : 'inherit' }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="subtitle2" color="primary">
        {translation.stats.entities[entity]}
      </Typography>
      <Typography sx={{ height: '100%', display: 'grid', alignItems: 'center' }}>{description}</Typography>
      <Box display="grid" justifyItems="end" width="100%">
        <Tooltip title={isFavourite? translation.input.removeFromFavorites : translation.input.addToFavorites}>
          <IconButton
            size="small"
            sx={{ width: 50, height: 50 }}
            onClick={(e) => {
              e.stopPropagation()
              isFavourite ? removeStatsFavourite(encodeURIComponent(path)) : addStatsFavourite({ title, description, type, entity, path })
            }}>
            {isFavourite ? <StarRounded color="primary" /> : <StarBorderRounded />}
          </IconButton>
        </Tooltip>
      </Box>
    </Container>
  )
}

export default StatCard

const mapTypeToImg: { [key in ChartType]: string } = {
  bar: imgBar,
  donut: imgDonut,
  line: imgLine,
  pie: imgPie,
  table: imgTable
}

//region Types

type Props = {
  type: ChartType
  entity: Entities
  title: string
  description: string
  path: string
}

//endregion

//region Style

const Container = styled(Card)<{ bg: string, disabled: boolean }>`
  ${({ disabled }) => !disabled && `cursor: pointer`};
  padding: ${(props) => props.theme.spacing(1)};
  width: 325px;
  height: 240px;
  background-image: url('${(props) => props.bg}') !important;
  filter: grayscale(100%) !important;

  display: grid;
  grid-template-rows: auto 30px 1fr 50px;
  &:hover {
    filter: none !important;
  }
`
//endregion
