import {useDispatch, useSelector} from 'react-redux'
import {getButtonLoading, getTranslation, getWarnings} from '../../store/selectors'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {setTitle} from '../../store/actions'
import {AutoSizeTable, CallsAndChatsSideColumn, ConfirmDialog, TitledCard} from '../common'
import {WarningItem} from '../../store/applicationState/types'
import {Box, Button, Typography} from '@mui/material'
import withExtraColumn from '../hoc/withExtraColumn'
import {LoadingButton} from '@mui/lab'

const Notifications: React.FC = () => {
  const [alertOpened, setAlertOpened] = useState<boolean>(false)
  const [actualOnClick, setActualOnClick] = useState<(() => void) | null>(null)
  const [actualConfirmationTitle, setActualConfirmationTitle] = useState<string>('')
  const [actualConfirmationMessage, setActualConfirmationMessage] = useState<string>('')

  const translation = useSelector(getTranslation)
  const warnings = useSelector(getWarnings)
  const dispatch = useDispatch()
  const buttonLoading = useSelector(getButtonLoading)

  const columns = useMemo(() => {
    return [
      {
        name: 'severity',
        header: <Typography sx={{ml: 1}}>{translation.notifications.severity}</Typography>,
        width: 90,
        cell: (rowData: WarningItem) => <Box
          sx={{bgcolor: rowData.severity, height: '100%', width: '90%', ml: 1}}>&nbsp;</Box>
      },
      {
        name: 'timestamp',
        header: translation.input.date,
        width: 170
      },
      {
        name: 'message',
        header: translation.input.message,
        cell: (rowData: WarningItem) => <Typography sx={{whiteSpace: 'pre-line'}}>{rowData.message}</Typography>
      },
      {
        name: '',
        header: '',
        width: 110,
        cell: (rowData: WarningItem) => rowData.actionButton?.onClick && (buttonLoading ?
          <LoadingButton
            onClick={() => {
            }}
            sx={{
              height: '100%',
              width: '100%',
              minWidth: '100px',
              maxWidth: '110px',
              mx: 1
            }}
            loading={buttonLoading}
            variant="outlined"
            disabled
          >
            disabled
          </LoadingButton> :
          <Button
            color="primary"
            variant="contained"
            sx={{
              height: '100%',
              width: '100%',
              minWidth: '100px',
              maxWidth: '110px',
              mx: 1
            }}
            onClick={() => {
              if (rowData.confirmationAlert?.message) {
                setActualOnClick(() => rowData.actionButton!.onClick())
                setActualConfirmationTitle(rowData.confirmationAlert.title)
                setActualConfirmationMessage(rowData.confirmationAlert.message)
                setAlertOpened(true)
              } else {
                // Esegui l'azione
                rowData.actionButton!.onClick()
              }
            }}
          >
            {rowData.actionButton?.label || ''}
          </Button>)
      }
    ]
  }, [translation.input.date, translation.notifications.severity, translation.input.message, buttonLoading])

  const handleConfirmation = useCallback((result: boolean) => {
    if (result && actualOnClick) {
      actualOnClick()
    }
    setAlertOpened(false)
    setActualOnClick(null)
    setActualConfirmationTitle('')
    setActualConfirmationMessage('')
  }, [actualOnClick])

  useEffect(() => {
    dispatch(setTitle(translation.notifications.topBarTitle))
  }, [translation.notifications.topBarTitle, dispatch])

  return (
    <>
      <TitledCard title={translation.notifications.title} sx={{height: '100%', width: '100%', px: 2, color: 'pink'}}>
        <AutoSizeTable<WarningItem>
          data={warnings || []}
          columns={columns}
          idField={'timestamp'}
          includeHeaders={true}
          enableDoubleClick={false}
          rowHeight={65}
        />
      </TitledCard>
      <ConfirmDialog
        show={alertOpened}
        hide={handleConfirmation}
        title={actualConfirmationTitle}
        message={actualConfirmationMessage}
      />
    </>
  )
}

export default withExtraColumn(Notifications, CallsAndChatsSideColumn, {mainPageMinWidth: 1600})