import { history } from '../constants'
import logger  from '../helpers/logger'
import {handleLogout, notify} from '../store/actions'
import axios, { AxiosResponse } from 'axios'
import qs from 'query-string'
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CacheManager from '../api/cache/CacheManager'
import { coreApi } from '../configuration'
import store from '../store'
import { makeHeader } from './helpers'
import { AnyObject } from './types'
import {getTranslation} from "../store/selectors"
import { useLoader } from './useLoader'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // se il problema è l'autenticazione esegue il logout
    if (error.response.status === 401) {
      store.dispatch(handleLogout() as any)
      history.push('/login')
    }
    return Promise.reject(error)
  }
)

interface Options<F> {
  lazy?: boolean
  initialFilters?: F
  admin?: boolean
  onSuccess?: () => void;
}

interface Parameters<F> {
  filters?: F
  id?: number | string
  afterPath?: string
  withoutNotification?: boolean
  withoutCache?: boolean
  noCache?: boolean
  loaderMessage?: string
}

export interface CallsOptions {
  afterPath?: string
  withoutNotification?: boolean
  loaderMessage?: string
}

interface HookObject<E = AnyObject, F = AnyObject> {
  result: E
  results: E[]
  total: number
  setResult: Dispatch<SetStateAction<E>>
  setResults: Dispatch<SetStateAction<E[]>>
  get: (args?: Parameters<F>) => Promise<{ payload: E[]; total: number }>
  getById: (args?: Parameters<F>) => Promise<{ payload: E; total: number }>
  post: (values?: any, opts?: CallsOptions) => Promise<AnyObject>
  put: (id: any, values?: any, opts?: CallsOptions) => Promise<AnyObject>
  remove: (id: any, opts?: CallsOptions) => Promise<AnyObject>
  download: (id: any, fileName: string, opts?: CallsOptions) => Promise<void>
  error: string
  isLoading: boolean
}

/**
 * Hook per la gestione delle chiamate rest autenticate verso il server
 * @template E tipo EntityObject, dice come è fatta l'entità
 * @template F tipo FilterObject, dice come sono fatti i possibili filtri di ricerca
 * @param url {string} path relativo a cui mandare wle richieste
 * @param options {Options} oggetto per la configurazione dell'hook
 * @param options.lazy {boolean} crea l'hook senza chiamare la get dell'url
 * @param options.initialFilters {Object} filtri iniziali per la get
 * @param options.admin {boolean} indica se all'endpoint fa parte degli endpoint per pannello di amministrazione
 * @returns {HookObject<E>}
 */

function useRest<E = AnyObject, F = AnyObject>(url: string, options: Options<F> = {}): HookObject<E, F> {
  const { lazy = true } = options
  const [total, setTotal] = useState(0)
  const [result, setResult] = useState<E>({} as E)
  const [results, setResults] = useState<E[]>([] as E[])
  const [error, setError] = useState<string>('')

  const dispatch = useDispatch()
  const translation = useSelector(getTranslation)
  const { setPageLoading } = useLoader()
  const [isLoading, setLoading] = useState<boolean>(false)
  //region METHODS
  /**
   * Funzione per il Get all su un endpoint preimpostato
   *
   * @param args {Parameters} campo contenente varie personalizzazzioni per la richiesta
   * @param args.filters filtri per la richiesta
   * @param args.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param args.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta (default false)
   *
   * @returns Promise<E[]>  in caso di successo il risultato è un array di entità richieste esegue anche il setResults(res.data)
   *                        in caso di errore fa il throw dell'errore
   */
  const get = useCallback(
    ({ filters, afterPath, withoutNotification = false, withoutCache = false  }: Parameters<F> = {}): Promise<{
      payload: E[]
      total: number
    }> => {
      let completeUrl = `${coreApi}/${url}`
      if (afterPath) completeUrl += afterPath
      if (filters && Object.keys(filters).length !== 0) {
        const filtersToSend: any = {}
        Object.entries(filters).forEach(([key, value]) => {
          if (value === null || value === undefined || value === '') return
          if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return
          // il core si aspetta il punto per gli oggetti annidati, ma hook form se usi il punto ti crea un oggetto
          // quindi su hook form va usato > al posto del punto nel nome del campo
          // inoltre per i campi che iniziano con - si vuole una ricerca "like", da annotare con un * iniziale
          const keyToSend = key.charAt(0) === '-' && !key.includes('@') ? `${key}`.substring(1) : key
          filtersToSend[keyToSend.replace(/>/g, '.')] = key.charAt(0) === '-' ? `*${value}` : value
        })
        completeUrl += '?'
        completeUrl += qs.stringify(filtersToSend)
      }
      const fromCache = !withoutCache && CacheManager.get(completeUrl)
      setLoading(true)
      // Se ha trovato l'entry nella cache si assicura di farla diventare una promise con resolve
      // in modo da poter utilizzare then catch e finally
      const getPromise = fromCache ? Promise.resolve(fromCache) : axios.get(completeUrl, makeHeader())

      // Altrimenti utilizza la get di axios e salva l'entry nella cache
      if (!fromCache) CacheManager.set(completeUrl, getPromise)

      logger.silly(`GET${fromCache ? ' FROM CACHE' : ''}: ${completeUrl}`)
      setLoading(true)
      return getPromise
        .then(({ data }) => {
          setTotal(data.total)
          setResults(data.payload)
          return data
        })
        .catch((error) => {
          setError(error.response.data.message)
          withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
        })
        .finally(() => setLoading(false))
    },
    [url, dispatch]
  )
  /**
   * Funzione per il Get One su un endpoint preimpostato
   *
   * @param args {Parameters} campo contenente varie personalizzazzioni per la richiesta
   * @param args.filters filtri per la richiesta
   * @param args.id {number | string} id dell'entità richiesta
   * @param args.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param args.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta (default false)
   *
   * @returns Promise<E>  in caso di successo il risultato è l'entità richiesta esegue anche il setResult(res.data)
   *                      in caso di errore fa il throw dell'errore
   */
  const getById = useCallback(
    ({ filters, id, afterPath, withoutNotification = false, noCache = false, loaderMessage }: Parameters<F> = {}): Promise<{
      payload: E
      total: number
    }> => {
      let completeUrl = `${coreApi}/${url}`
      if (id) completeUrl += `/${id}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        completeUrl += '?'
        const arrayFilters: string[] = []
        Object.entries(filters).forEach(([key, value]) => {
          if (value === null || value === undefined) return
          arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
          if (arrayFilters.length > 0) {
            completeUrl += '&'
            completeUrl += arrayFilters.join('&')
          }
        })
      }

      setLoading(true)
      logger.silly(`GET: ${completeUrl}`)

      return axios
        .get(completeUrl, makeHeader(undefined, undefined, noCache))
        .then(({ data }) => {
          setResult(data.payload)
          return data
        })
        .catch((error) => {
          setError(error.response.data.message)
          withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url, dispatch]
  )
  /**
   * Funzione per il Post su un endpoint preimpostato
   *
   * @param values {any} oggetto contenente i valori da inviare nella richiesta
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const post = useCallback(
    (values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${coreApi}/${url}`
      if (opts.afterPath) completeUrl += opts.afterPath

      setPageLoading(true, opts.loaderMessage)

      logger.silly(`POST: ${completeUrl}`)
      return axios
        .post(completeUrl, values || null, makeHeader())
        .then(({ data }) => {
          opts.withoutNotification || dispatch(notify({ message: translation.notifications.success, severity: 'success' }))
          // Elimino la entry dalla cache
          CacheManager.deleteEntities(completeUrl)
          return data
        })
        .catch((error) => {
          setError(error.response.data.message)
          opts.withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
          throw error
        })
        .finally(() => {
          setPageLoading(false)
        })
    },
    [url, dispatch, translation.notifications.success, setPageLoading]
  )
  /**
   * Funzione per il Put su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da modificare
   * @param values {any} oggetto contenente i valori da inviare nella richiesta
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const put = useCallback(
    (id: any, values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${coreApi}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath

      setPageLoading(true, opts.loaderMessage)

      logger.silly(`PUT: ${completeUrl}`)
      return axios
        .put(completeUrl, values || null, makeHeader())
        .then(({ data }) => {
          opts.withoutNotification || dispatch(notify({ message: translation.notifications.success, severity: 'success' }))
          // Elimino la entry dalla cache
          const chunks = completeUrl.split('/')
          chunks.pop()
          CacheManager.deleteEntities(chunks.join('/'))
          return data
        })
        .catch((error) => {
          setError(error.response.data.message)
          opts.withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
          throw error
        })
        .finally(() => {
          setPageLoading(false)
        })
    },
    [url, dispatch, translation.notifications.success, setPageLoading]
  )
  /**
   * Funzione per il Delete su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da cancellare
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const remove = useCallback(
    (id: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${coreApi}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath

      setPageLoading(true, opts.loaderMessage)

      logger.silly(`DELETE: ${completeUrl}`)
      return axios
        .delete(completeUrl, makeHeader())
        .then(({ data }) => {
          opts.withoutNotification || dispatch(notify({ message: translation.notifications.success, severity: 'success' }))
          // Elimino la entry dalla cache
          const chunks = completeUrl.split('/')
          chunks.pop()
          CacheManager.deleteEntities(chunks.join('/'))
          return data
        })
        .catch((error) => {
          setError(error.response.data.message)
          opts.withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
          throw error
        })
        .finally(() => {
          setPageLoading(false)
        })
    },
    [url, dispatch, translation.notifications.success, setPageLoading]
  )
  /**
   * Funzione per il Download su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da cancellare
   * @param fileName {string} nome del file da salvare
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const download = useCallback(
    (id: any, fileName: string, opts: CallsOptions = {}): Promise<void> => {
      let completeUrl = `${coreApi}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath

      setPageLoading(true, opts.loaderMessage)

      return axios
        .get(completeUrl, makeHeader('blob'))
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setTotal(1)
        })
        .catch((error) => {
          setError(error.response.data.message)
          opts.withoutNotification || dispatch(notify({ message: error.response.data.message, severity: 'error' }))
          throw error
        })
        .finally(() => {
          setPageLoading(false)
        })
    },
    [url, dispatch, setPageLoading]
  )
  //endregion

  useEffect(() => {
    if (lazy) return
    get({ filters: options.initialFilters }).catch((err) => logger.error(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, lazy])

  return useMemo(() => ({
    get,
    getById,
    post,
    put,
    remove,
    download,
    result,
    results,
    total,
    setResult,
    setResults,
    error,
    isLoading
  }), [get, getById, post, put, remove, download, result, results, total, error, isLoading])

}

export default useRest
