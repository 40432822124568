import {useNavigate} from "react-router-dom";
import React from "react";
import {InstanceType} from "../SocialSummary";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {useSelector} from 'react-redux'
import {getTranslation} from "../../../../../store/selectors";

const availableSocials = [InstanceType.Whatsapp]

const NewSocialPickerPage: React.FC = ()=>{

  const navigate = useNavigate()
  const translation = useSelector(getTranslation)




  return (
    <Container>
      <CustomPaper>{translation.pages.ADMINISTRATION_SOCIAL.choose}</CustomPaper>
      <ButtonsContainer>
        <SocialContainer type={InstanceType.Whatsapp} onClick={()=> availableSocials.includes(InstanceType.Whatsapp) && navigate(`/administration/social/${InstanceType.Whatsapp}/new`)}>
          <WhatsAppIcon fontSize={'large'}/>
        </SocialContainer>
        <SocialContainer type={InstanceType.Telegram} onClick={()=> availableSocials.includes(InstanceType.Telegram) && navigate(`/administration/social/${InstanceType.Telegram}/new`)}>
          <TelegramIcon fontSize={'large'}/>
        </SocialContainer>
        <SocialContainer type={InstanceType.Facebook} onClick={()=> availableSocials.includes(InstanceType.Facebook) && navigate(`/administration/social/${InstanceType.Facebook}/new`)} >
          <FacebookIcon fontSize={'large'}/>
        </SocialContainer>
      </ButtonsContainer>
    </Container>
  )
}

export default NewSocialPickerPage


const Container = styled.div`
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-rows: auto 1fr;
`

const SocialContainer = styled(Paper)<{type: InstanceType}>`
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    filter: brightness(1.1);
    cursor: pointer;
    transition: 1s;
    cursor:${({type})=>{
        switch(type) {
            case InstanceType.Telegram: return 'not-allowed';
            case InstanceType.Facebook: return 'not-allowed';
            case InstanceType.Whatsapp: return 'pointer';
        }
    }};
    background-color:${({type})=>{
        switch(type) {
          case InstanceType.Telegram: return 'rgba(122, 122, 122, 1)';
          case InstanceType.Facebook: return 'rgba(122 ,122, 122, 1)';
        }
    }};
    &:hover{
        background-color:${({type})=>{
          switch(type) {
            //case InstanceType.Telegram: return 'rgba(36, 161, 222, 0.2)';
            case InstanceType.Whatsapp: return 'rgba(37 ,211, 102, 0.2)';
            //case InstanceType.Facebook: return 'rgba(59 ,89, 152, 0.2)';
          }
        }};
        transition: 0.4s;
    }
`
const ButtonsContainer = styled(Paper)`
    padding: 30px 0 ;
    display: flex;
    height: 100%;
    gap: 1rem;
    width: 100%;
    justify-content: space-around;
    align-items: center;
`



const CustomPaper = styled(Paper)`
    width: auto;
    padding: 12px 8px;
`

// endregion
