import React, {useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {InstanceType, SocialInstanceInfo} from '../../SocialSummary'
import {MenuItem} from '@mui/material'
import CommandButtons from '../CommandButtons'
import {useMatch, useNavigate} from 'react-router-dom'
import useCoordinatorRest from '../../../../../../hooks/useCoordinatorRest'
import {Department, iSocial} from '../../../../../../api/types'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import StatusInfo from '../StatusInfo'
import Paper from '@mui/material/Paper'
import SocialHeader from '../SocialHeader'
import {DateTime} from 'luxon'
import {coordinatorRestUrl} from '../../../../../../configuration'
import {getTranslation} from '../../../../../../store/selectors'
import {setTitle} from '../../../../../../store/applicationState/actions'
import {logger} from '../../../../../../helpers'
import {CardWithButtons, Input, OneItemRow, Select, Switch, ThreeItemsRow, TwoItemsRow} from '../../../../../common'


interface Props{
  instance: SocialInstanceInfo | null
  departments: Department[]
  id?: number | string
}

interface FormData {
  name: string,
  accessToken: string,
  department: string,
  accountId: string,
  phoneNumberId: string,
  phoneNumber: string,
  webhookUrl: string,
  autoRun: boolean,
  verifyToken: string,
}

const defaultValues: FormData = {
  name: '',
  accessToken: '',
  department: '',
  accountId: '',
  phoneNumberId: '',
  phoneNumber: '',
  webhookUrl:`${coordinatorRestUrl}/whatsapp/webhook`,
  autoRun: false,
  verifyToken: 'Secret!'
}


const WhatsappDetails: React.FC<Props> = ({ instance, departments, id}) => {

  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const match = useMatch('administration/social/:type/:id')
  const typeParam = match?.params?.type
  const { post: createWhatsapp, put: updateWhatsapp, result: whatsapp, getById: getWhatsappById, remove: removeWhatsapp, setResult: setWhatsapp} = useCoordinatorRest<iSocial>('socials')


  const {
    control,
    handleSubmit,
    reset,
    formState: {isDirty},
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {...defaultValues},
    shouldUnregister: false
  })

  const isNew = useMemo(() => id === 'new', [id])

  const status = useMemo(()=> whatsapp?.isRunning , [whatsapp])

  const onSubmit = useCallback((data: FormData) => {
    const dataToSubmit = {
      name: data.name || '',
      accessToken: data.accessToken ||'',
      departmentId: data.department ,
      accountId: data.accountId ||'',
      phoneNumberId: data.phoneNumberId || '',
      phoneNumber: data.phoneNumber || '',
      webhookUrl: data.webhookUrl || '',
      autoRun: data.autoRun,
      verifyToken: data.verifyToken,
      type: InstanceType.Whatsapp
    }
    if(isNew) {
      createWhatsapp(dataToSubmit).then(({payload}) => {
        navigate(`/administration/social/${payload.type}/${payload.id}`)
      }).catch((err) => logger.error(err))
    } else {
      updateWhatsapp(id, dataToSubmit).then(({payload}) => setWhatsapp(payload)).catch((err) => logger.error(err))
    }
  }, [createWhatsapp, id, isNew, navigate, setWhatsapp, updateWhatsapp])

  const refreshFields = useCallback(() => {
    reset({
      name: whatsapp?.name || '',
      accessToken: whatsapp?.accessToken || '',
      department: String(whatsapp?.departmentId) || '',
      accountId: whatsapp?.accountId || '',
      phoneNumberId: whatsapp?.phoneNumberId || '',
      webhookUrl: whatsapp?.webhookUrl || defaultValues.webhookUrl,
      autoRun: whatsapp?.autoRun || false,
      verifyToken: whatsapp?.verifyToken || '',
      phoneNumber: whatsapp?.phoneNumber || '',
    })
  }, [reset, whatsapp])

  const dispatch = useDispatch()

  const toggleInstance = useCallback(()=>{
    if(whatsapp && whatsapp.isRunning){
      updateWhatsapp( id , {}, {afterPath: '/stop-instance' }).then((res)=>{
        setWhatsapp(res.payload)
      })
    }else{
      updateWhatsapp( id , {}, {afterPath: '/start-instance' }).then((res)=>{
        setWhatsapp(res.payload)
      })
    }
  },[updateWhatsapp, id, whatsapp, setWhatsapp])

  //Gestione titolo Pagina
  useEffect(() => {
    dispatch(
      setTitle({
        title: `${translation.menu.social}/${whatsapp?.name || translation.pages.ADMINISTRATION_SOCIAL.new} ${typeParam}`,
        breadcrumb: [ '/administration/social', `/administration/social/Whatsapp/${id}`]
      })
    )
  }, [translation, dispatch, id, whatsapp, typeParam, instance?.type])

  useEffect(() => {
    if (id === 'new') return
    getWhatsappById({id: id}).catch((e) => logger.error(e))
  }, [id, getWhatsappById])

  useEffect(() => {
    refreshFields()
  }, [refreshFields])

  return (
    <Container>
      <MainFormContainer isNew={isNew}>

        {instance && <SocialHeader type={InstanceType.Whatsapp} isTokenExpired={
/*
          instance?.isTokenExpired
*/
          false
        } />}

        <form onSubmit={handleSubmit(onSubmit)} style={{height: "auto"}}>
          <CardWithButtons
            entity="social"
            title={!isNew ? `${translation.pages.ADMINISTRATION_SOCIAL.details} ${whatsapp.name}` : translation.pages.ADMINISTRATION_SOCIAL.new}
            isNew={isNew}
            closePath={`/administration/social`}
            isDirty={isDirty}
            withScrollBar={true}
            refreshFields={refreshFields}
            remove={() => removeWhatsapp(whatsapp.id)
              .then(() => navigate(`/administration/social`))
              .catch((err) => logger.error(err))}
            sx={{height: 'fit-content'}}>
            <CardContainer isNew={isNew}>
              <InputsSection>
                <ThreeItemsRow>
                  <Input<FormData>
                    name="name"
                    control={control}
                    label={`${translation.input.name} *`}
                  />
                  <Select<FormData>
                    name={'department'}
                    control={control}
                    label={`${translation.input.departments} *`}
                  >
                    {departments?.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Switch<FormData>
                    name="autoRun"
                    control={control}
                    labelPosition={'left'}
                    label={translation.input.autoRun}>
                  </Switch>
                </ThreeItemsRow>
                <ThreeItemsRow>
                  <Input<FormData>
                    name="accountId"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.accountId} *`}
                  />
                  <Input<FormData>
                    name="phoneNumberId"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.phoneNumberId} *`}
                  />
                  <Input<FormData>
                    name="phoneNumber"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.phoneNumber} *`}
                  />
                </ThreeItemsRow>
                <TwoItemsRow>
                <Input<FormData>
                    name="verifyToken"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.verifyToken} *`}
                  />
                  <Input<FormData>
                    name="accessToken"
                    control={control}
                    label={`${translation.pages.ADMINISTRATION_SOCIAL.accessToken} *`}
                    hint={whatsapp?.tokenExpiresAt ? `${translation.pages.ADMINISTRATION_SOCIAL.tokenValidUntil} ${DateTime.fromISO(whatsapp?.tokenExpiresAt).toFormat('dd-LLL-yyyy HH:mm:ss')}` : undefined}
                  />
                </TwoItemsRow>
                <OneItemRow>
                <Input<FormData>
                  name="webhookUrl"
                  InputProps={{
                    readOnly: true,
                  }}
                  control={control}
                  label={`${translation.pages.ADMINISTRATION_SOCIAL.webhookUrl} *`}
                />
                </OneItemRow>
              </InputsSection>
            </CardContainer>
          </CardWithButtons>
        </form>
        {!isNew && instance && <CustomPaper>
            <InfoContainer>
                <StatusInfo status={status} instance={instance} withoutAuth={true}/>
              {id && !isNew && <CommandButtons toggleInstance={()=>toggleInstance()} status={status} instance={instance}/>}
            </InfoContainer>
        </CustomPaper>}
      </MainFormContainer>
    </Container>
  )
}


export default WhatsappDetails




// region style
const Container = styled.div`
    height: 100%;
    width: 100%;
`

const CustomPaper = styled(Paper)`
    width: min-content;
    padding: 12px 8px;
`

const MainFormContainer = styled.div<{ isNew: boolean }>`
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
`

const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.div<{ isNew: boolean }>`
  display: flex;
  flex-direction: column;
`

const InfoContainer = styled.div`
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
`

// endregion
