import React from "react";
import styled from "styled-components";
import {Fab, Typography} from "@mui/material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useSelector } from 'react-redux'
import {SocialInstanceInfo} from "../SocialSummary";
import {getTranslation} from "../../../../../store/selectors";

interface Props{
  instance?: SocialInstanceInfo
  status: boolean
  toggleInstance: ()=> void
}

const CommandButtons: React.FC<Props> = ({ instance , status, toggleInstance}) => {
  const translation = useSelector(getTranslation)

  return !!instance ? (
    <Container>
      <ButtonContainer>
        <Fab  size="small" onClick={toggleInstance} sx={{ mx: 1, bgcolor: status ? 'error.main' : 'success.main' , '&:hover': { bgcolor: status ? 'error.light' : 'success.light'   }}}>
          <PowerSettingsNewIcon htmlColor="#FFFFFF"  />
        </Fab>
        <Typography>
         <strong>{!status ? translation.pages.ADMINISTRATION_SOCIAL.start : translation.pages.ADMINISTRATION_SOCIAL.stop}</strong>
        </Typography>
      </ButtonContainer>
    </Container>) : (<></>)
}

export default CommandButtons


const Container = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    margin-right: 3rem ;
`


const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column; 
    gap: 7px;
`