import React, {useCallback, useMemo} from 'react'
import {Department, iSocial} from '../../../../api/types'
import {EditOutlined} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import useCoordinatorRest from '../../../../hooks/useCoordinatorRest'
import {DateTime} from 'luxon'
import {getTranslation} from '../../../../store/selectors'
import {AutoSizeTable, Filters, TitledCard} from '../../../common'
import {usePaginationAndFilters, useRest} from '../../../../hooks'
import {usePageLoadingState} from '../../../../hooks/usePageLoadingState'

export interface SocialInstanceInfo {
  id: string
  name: string
  department?: number
  type: InstanceType
  departmentName?: string
  isRunning: boolean
}

export enum InstanceType{
  Telegram = 'Telegram',
  Whatsapp = 'Whatsapp',
  Facebook = 'Facebook'
}

export const SocialSummary: React.FC = ()=>{

  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const { results: departments, isLoading: isDepartmentsLoading } = useRest<Department>('departments', {lazy: false})
  const { get, total, results: socials, loading: isSocialsLoading} = useCoordinatorRest<iSocial>('socials', {lazy: false})

  const loading = useMemo(() => [isSocialsLoading, isDepartmentsLoading], [isSocialsLoading, isDepartmentsLoading])
  usePageLoadingState(loading)



  const mergedInstances: iSocial[] = useMemo(() => {
    if (socials.length > 0 && departments.length > 0) {
      return socials.map((restInstance) => {
        const department = departments.find(dep => dep.id === restInstance.departmentId)

        return {
          ...restInstance,
          departmentName: department ? department.name : '',
        }
      })

    }else return []
  }, [ socials, departments])

  const {
    search,
    page,
    changePage,
    pageLimit,
    changePageLimit,
    sort,
    sortBy,
    sortOrder
  } = usePaginationAndFilters<FiltersType>({ get, filters: defaultFilters })

  const renderType = useCallback((type: InstanceType)=>{
    switch(type) {
      case InstanceType.Telegram : return <TelegramIcon/>
      case InstanceType.Whatsapp : return <WhatsAppIcon/>
      case InstanceType.Facebook: return <FacebookIcon/>
      default: return <></>
    }
  },[])

  const renderStatus = useCallback((cellData: iSocial)=>{
    return <InstanceCell>
      <StatusDot verified={true} running={cellData.isRunning}  />
      <IconButton size="small" onClick={() => navigate(`${cellData.id}`)}>
        <EditOutlined />
      </IconButton>
    </InstanceCell>
  },[navigate])


  return (
    <Container>
    <FiltersContainer>
      <Filters<FiltersType>
        filters={[
          {
            placeholder: translation.pages.ADMINISTRATION_SOCIAL.name,
            fieldName: 'name',
            type: 'text'
          }
        ]}
        title={translation.input.search}
        defaultValues={defaultFilters}
        onSubmit={(data) => search(data)}
        newButton={() => navigate('/administration/social/type/new')}
      />
    </FiltersContainer>
    <TitledCard
      title={translation.menu.social}
      sx={{ height: '100%' }}
      buttons={[]}>
      <AutoSizeTable<iSocial>
        data={mergedInstances}
        style={{height: '100%'}}
        onCellClick={(_event, {  rowData })=>navigate(`/administration/social/${rowData.type}/${rowData.id}`)}
        columns={[
          {
            name: 'type',
            header: translation.pages.ADMINISTRATION_SOCIAL.socialType,
            cell: (rowData)=> renderType(rowData.type),
            width: '5%'
          },
          {
            name: 'departmentName',
            header: translation.pages.ADMINISTRATION_SOCIAL.departmentName,
            width: '30%'
          },
          {
            name: 'name',
            header: translation.pages.ADMINISTRATION_SOCIAL.instanceName,
            width: '20%'
          },
          {
            name: 'tokenExpiresAt',
            header: translation.pages.ADMINISTRATION_SOCIAL.socialStatus,
            cell:(rowData)=>  rowData.tokenExpiresAt ? DateTime.fromISO(rowData.tokenExpiresAt).toFormat('dd-LLL-yyyy HH:mm:ss') : translation.pages.ADMINISTRATION_SOCIAL.withoutExpiration,
            width: '35%'
          },
          {
            name: 'running',
            header: translation.pages.ADMINISTRATION_SOCIAL.socialStatus,
            cell:(rowData)=> renderStatus(rowData),
            width: '10%'
          }
        ]}
        idField={'id'}
        includeHeaders={true}
        rowHeight={40}
        fixedRowCount={1}
        pagination={{
          count: total,
          rowsPerPage: pageLimit,
          rowsPerPageOptions: [25, 50, 100, 200, 500, 1000],
          page: page,
          onPageChange: (_, page) => {
            changePage(page)
          },
          onRowsPerPageChange: (e:any) => {
            !isNaN(parseInt(e.target.value)) && changePageLimit(parseInt(e.target.value))
          }
        }}
        sort={sort}
        orderBy={sortBy}
        orderDirection={sortOrder}
      />
    </TitledCard>
  </Container>)
}

const defaultFilters = {
  'name': ''
}

// region types
type FiltersType = { 'name': string }
// endregion


const Container = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
`

const InstanceCell = styled.div`
    display: flex;
    justify-content: start;
    gap: 10px;
    align-items: center;
`

const FiltersContainer = styled.div`
  display: grid;
  column-gap: 10px;
  width: 100%;
  height: 100%;
`

const StatusDot = styled.div<{ verified: boolean, running: boolean }>`  
  height: 16px;
  width: 16px;
  border-radius: 8px;  
  background-color: ${({verified, running, theme}) => {
    if(verified && running) return theme.palette.success.main
    if(running && !verified) return theme.palette.warning.main
    if(!running) return theme.palette.error.main
  }
};  
`