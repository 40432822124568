import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import styled from 'styled-components'

interface Props {
  show: boolean
  hide: (result: boolean) => void
  title: string
  message: string
  translation: any
}

export const CampaignConfirmDialog: React.FC<Props> = ({ show, hide, title, message, translation }) => {
  return (
    <Dialog open={show} onClose={() => hide(false)} maxWidth="xs" fullWidth>
      <DialogTitle>
        <TitleContainer>
          <WarningAmberIcon color="warning" />
          <span>{title}</span>
        </TitleContainer>
      </DialogTitle>
      <DialogContent>
        <WarningContainer>
          <DialogContentText>{message}</DialogContentText>
          <WarningMessage>
            {translation.alert.deleteCampaign.warning}
          </WarningMessage>
        </WarningContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => hide(false)} color="inherit">
          {translation.button.cancel}
        </Button>
        <Button onClick={() => hide(true)} color="error" variant="contained">
          {translation.button.delete}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`

const WarningMessage = styled(DialogContentText)`
  color: ${({ theme }) => theme.palette.warning.main};
  background-color: ${({ theme }) => theme.palette.warning.light};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-top: ${({ theme }) => theme.spacing(1)};
`