import React from 'react'
import {Route} from 'react-router-dom'

// Calls
const ContactCenterTimes = React.lazy(() => import('./calls/contact-center-times/ContactCenterTimes'))
const CallsHandledByHourBands = React.lazy(() => import('./calls/handled-calls-by-hourbands/CallsHandledByHourBands'))
const CallsDailyConcentration = React.lazy(() => import('./calls/daily-concentration/CallsDailyConcentration'))
const ReportCallsByInterval = React.lazy(() => import('./calls/report-calls-by-interval/ReportCallsByInterval'))
const HandledCalls = React.lazy(() => import('./calls/handled-calls/HandledCalls'))

// Inbounds
const InboundsBySource = React.lazy(() => import('./inbounds/by-source-and-month/InboundsBySource'))
const InboundsDetailsByInterval = React.lazy(() => import('./inbounds/details-by-interval/InboundsDetailsByInterval'))
const InboundsDetailsByServices = React.lazy(() => import('./inbounds/details-by-services/InboundsDetailsByServices'))
const InboundsDetailsByUsers = React.lazy(() => import('./inbounds/details-by-users/InboundsDetailsByUsers'))
const InboundsDetailsByWeekdays = React.lazy(() => import('./inbounds/details-by-weekdays/InboundsDetailsByWeekdays'))
const QueueTimes = React.lazy(() => import('./inbounds/queue-times/QueueTimes'))
const InboundsOperatorsActivity = React.lazy(() => import('./inbounds/operators-activity/InboundsOperatorsActivity'))
const InboundsLostCalls = React.lazy(() => import('./inbounds/lost-calls/InboundsLostCalls'))
const InboundsLostCallsByHourbands = React.lazy(
  () => import('./inbounds/lost-calls-by-hourbands/InboundsLostCallsByHourbands')
)
const TopNetwork = React.lazy(() => import('./inbounds/report-topnetwork/TopNetwork'))
// Outbounds
const OutboundsByInterval = React.lazy(() => import('./outbounds/by-interval/OutboundsByInterval'))
const OutboundsDetailsByUsers = React.lazy(() => import('./outbounds/details-by-users/OutboundsDetailsByUsers'))
const OutboundsDetailsByWeekdays = React.lazy(
  () => import('./outbounds/details-by-weekdays/OutboundsDetailsByWeekdays')
)
const OutboundsDetailsByServices = React.lazy(() => import('./outbounds/details-by-service/OutboundsDetailsByServices'))
// Callbacks
const CallbacksByInterval = React.lazy(() => import('./callbacks/by-interval/CallbacksByInterval'))
const CallbacksDetailsByUsers = React.lazy(() => import('./callbacks/details-by-users/CallbacksDetailsByUsers'))
const CallbacksDetailsByWeekdays = React.lazy(
  () => import('./callbacks/details-by-weekdays/CallbacksDetailsByWeekdays')
)
const CallbacksDetailsByServices = React.lazy(() => import('./callbacks/details-by-service/CallbacksDetailsByServices'))
// Predictive
const PredictiveByInterval = React.lazy(() => import('./predictive/by-interval/PredictiveByInterval'))
const PredictiveDetailsByUsers = React.lazy(() => import('./predictive/details-by-users/PredictiveDetailsByUsers'))
const PredictiveDetailsByWeekdays = React.lazy(
  () => import('./predictive/details-by-weekdays/PredictiveDetailsByWeekdays')
)
const PredictiveDetailsByServices = React.lazy(() => import('./predictive/details-by-service/PredictiveDetailsByServices'))
// Chats
const ChatsDetailsByInterval = React.lazy(() => import('./chats/details-by-interval/ChatsDetailsByInterval'))
const ChatDetailsByWeekdays = React.lazy(() => import('./chats/details-by-weekdays/ChatDetailsByWeekdays'))
const ChatDetailsByUsers = React.lazy(() => import('./chats/details-by-users/ChatDetailsByUsers'))
const ChatDetailsByServices = React.lazy(() => import('./chats/details-by-services/ChatDetailsByServices'))
// Users
const ConsultantsOperativity = React.lazy(() => import('./users/consultants-operativity/ConsultantsOperativity'))
const OperatorStats = React.lazy(() => import('./users/operator-stats/OperatorStats'))
const ConsultantsOperativityByHourBands = React.lazy(
  () => import('./users/consultants-operativity-by-hour-bands/ConsultantsOperativityByHourBands')
)

export const renderStatsRoutes = () => {

  return (
    [
      <Route key={'report-calls-by-interval'} path={'/tools/stats/calls/report-calls-by-interval'}
             element={<ReportCallsByInterval/>}/>,
      <Route key={'contact-center-times'} path={'/tools/stats/calls/contact-center-times'}
             element={<ContactCenterTimes/>}/>,
      <Route key={'handled-by-hourbands'} path={'/tools/stats/calls/handled-by-hourbands'}
             element={<CallsHandledByHourBands/>}/>,
      <Route key={'daily-concentration'} path={'/tools/stats/calls/daily-concentration'}
             element={<CallsDailyConcentration/>}/>,
      <Route key={'handled-calls'} path={'/tools/stats/calls/handled-calls'} element={<HandledCalls/>}/>,

      <Route key={'details-by-users'} path={'/tools/stats/inbounds/details-by-users'}
             element={<InboundsDetailsByUsers/>}/>,
      <Route key={'details-by-interval'} path={'/tools/stats/inbounds/details-by-interval'}
             element={<InboundsDetailsByInterval/>}/>,
      <Route key={'details-by-weekdays'} path={'/tools/stats/inbounds/details-by-weekdays'}
             element={<InboundsDetailsByWeekdays/>}/>,
      <Route key={'details-by-services'} path={'/tools/stats/inbounds/details-by-services'}
             element={<InboundsDetailsByServices/>}/>,
      <Route key={'queue-times'} path={'/tools/stats/inbounds/queue-times'}
             element={<QueueTimes/>}/>,
      <Route key={'operators-activity'} path={'/tools/stats/inbounds/operators-activity'}
             element={<InboundsOperatorsActivity/>}/>,
      <Route key={'by-source-and-month'} path={'/tools/stats/inbounds/by-source-and-month'}
             element={<InboundsBySource/>}/>,
      <Route key={'lost-calls'} path={'/tools/stats/inbounds/lost-calls'} element={<InboundsLostCalls/>}/>,
      <Route key={'lost-calls-by-hourbands'} path={'/tools/stats/inbounds/lost-calls-by-hourbands'}
             element={<InboundsLostCallsByHourbands/>}/>,
      <Route key={'top-network'} path={'/tools/stats/inbounds/top-network'} element={<TopNetwork/>}/>,

      <Route key={'by-interval'} path={'/tools/stats/outbounds/by-interval'} element={<OutboundsByInterval/>}/>,
      <Route key={'details-by-users'} path={'/tools/stats/outbounds/details-by-users'}
             element={<OutboundsDetailsByUsers/>}/>,
      <Route key={'details-by-weekdays'} path={'/tools/stats/outbounds/details-by-weekdays'}
             element={<OutboundsDetailsByWeekdays/>}/>,
      <Route key={'details-by-service'} path={'/tools/stats/outbounds/details-by-service'}
             element={<OutboundsDetailsByServices/>}/>,

      <Route key={'callbacks-by-interval'} path={'/tools/stats/callbacks/by-interval'}
             element={<CallbacksByInterval/>}/>,
      <Route key={'callbacks-details-by-users'} path={'/tools/stats/callbacks/details-by-users'}
             element={<CallbacksDetailsByUsers/>}/>,
      <Route key={'callbacks-details-by-weekdays'} path={'/tools/stats/callbacks/details-by-weekdays'}
             element={<CallbacksDetailsByWeekdays/>}/>,
      <Route key={'callbacks-details-by-service'} path={'/tools/stats/callbacks/details-by-service'}
             element={<CallbacksDetailsByServices/>}/>,

      <Route key={'predictive-by-interval'} path={'/tools/stats/predictive/by-interval'}
             element={<PredictiveByInterval/>}/>,
      <Route key={'predictive-details-by-users'} path={'/tools/stats/predictive/details-by-users'}
             element={<PredictiveDetailsByUsers/>}/>,
      <Route key={'predictive-details-by-weekdays'} path={'/tools/stats/predictive/details-by-weekdays'}
             element={<PredictiveDetailsByWeekdays/>}/>,
      <Route key={'predictive-details-by-service'} path={'/tools/stats/predictive/details-by-service'}
             element={<PredictiveDetailsByServices/>}/>,

      <Route key={'details-by-interval'} path={'/tools/stats/chats/details-by-interval'}
             element={<ChatsDetailsByInterval/>}/>,
      <Route key={'details-by-weekdays'} path={'/tools/stats/chats/details-by-weekdays'}
             element={<ChatDetailsByWeekdays/>}/>,
      <Route key={'details-by-users'} path={'/tools/stats/chats/details-by-users'} element={<ChatDetailsByUsers/>}/>,
      <Route key={'details-by-services'} path={'/tools/stats/chats/details-by-services'}
             element={<ChatDetailsByServices/>}/>,

      <Route key={'consultants-operativity'} path={'/tools/stats/users/consultants-operativity'}
             element={<ConsultantsOperativity/>}/>,
      <Route key={'operator-stats'} path={'/tools/stats/users/operator-stats'} element={<OperatorStats/>}/>,
      <Route
        key={'consultants-operativity-by-hour-bands'}
        path={'/tools/stats/users/consultants-operativity-by-hour-bands'}
        element={<ConsultantsOperativityByHourBands/>}
      />
    ]
  )
}
