import { CloseRounded, DeleteOutlineRounded, ReplayRounded, SaveRounded } from '@mui/icons-material'
import { Box, Fab, Tooltip } from '@mui/material'
import { getTranslation } from '../../store/selectors'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import usePermissions from '../../hooks/usePermissions'
import { ConfirmDialog } from './ConfirmDialog'
import { useTheme } from '@mui/material/styles'
import { CampaignConfirmDialog } from '../pages/administration/predictiveCampaigns/CampaignoConfirmDialog'


interface Props {
  reset?: Function
  close?: Function
  remove?: Function
  customButton?: ReactElement
  dirty?: boolean
  isNew?: boolean
  entity?: string
  disable?: boolean
  campaign?: { isRunning: boolean }
}

/**
 * Componente contenente 4 bottoni
 *  1) salva (obligatorio) 2) annulla (facoltativo) 3) elimina (facoltativo) 4) chiudi(facoltativo)
 */
export const DetailsButton: React.FC<Props> = (props) => {
  const {reset, close, remove, customButton, dirty, entity, isNew, disable, campaign} = props

  const [showDialog, setShowDialog] = useState(false)
  const [saving, setSaving] = useState(false)

  const translation = useSelector(getTranslation)
  const theme = useTheme()

  const { userCan } = usePermissions(entity)

  const removeWithConfirmation = (result: boolean) => {
    if (result && remove) remove()
    else setShowDialog(false)
  }

  useEffect(() => {
    const alertIfDirty = (e: BeforeUnloadEvent) => {
      if (!saving) {
        e.preventDefault()
        setSaving(false)
        return (e.returnValue = 'Are you sure you want to exit?')
      }
    }
    dirty && window.addEventListener('beforeunload', alertIfDirty)
    return () => {
      if (dirty) window.removeEventListener('beforeunload', alertIfDirty)
    }
  }, [dirty, saving])

  const UndoOrClose: React.ReactElement | null = useMemo(() => {
    if (dirty && reset) {
      return (
        <Tooltip title={translation.input.undo}>
          <span>
            <Fab size="small" sx={{ mx: 1, bgcolor: theme.palette.mode === 'dark' ? 'grey.500' : 'grey.300' }}
              onClick={() => reset()}>
              <ReplayRounded color="action" />
            </Fab>
          </span>
        </Tooltip>
      )
    }

    if (!dirty && close) {
      return (
        <Tooltip title={translation.input.close}>
          <span>
            <Fab size="small" sx={{
              mx: 1,
              bgcolor: theme.palette.mode === 'dark' ? 'grey.500' : 'grey.300'
            }} onClick={() => close()}>
              <CloseRounded color="action" />
            </Fab>
          </span>
        </Tooltip>
      )
    }

    return null
  }, [dirty, close, reset, theme.palette.mode, translation.input.close, translation.input.undo])

  const Remove: React.ReactElement | null = useMemo(() => {
    if (!remove || isNew) {
      return null
    } else {
      return (
        <Tooltip title={translation.input.delete}>
          <span>
            <Fab
              size="small"
              sx={{bgcolor: 'error.main', mx: 1, '&:hover': {bgcolor: 'error.light'}}}
              disabled={!userCan.delete || disable}
              onClick={() => setShowDialog(true)}>
              <DeleteOutlineRounded htmlColor="#FFFFFF" />
            </Fab>
          </span>
        </Tooltip>
      )
    }
  }, [remove, userCan, isNew, translation.input.delete, disable])

  const Save: React.ReactElement | null = useMemo(() => {
    return (
      <Tooltip title={translation.input.save}>
        <span>
          <Fab
            type="submit"
            size="small"
            onClick={() => setSaving(true)}
            disabled={!dirty || (isNew ? !userCan.create : !userCan.update)}
            sx={{ bgcolor: 'primary.dark', mx: 1, '&:hover': { bgcolor: 'primary.main' } }}>
            <SaveRounded htmlColor="#FFFFFF" />
          </Fab>
        </span>
      </Tooltip>
    )
  }, [isNew, userCan, dirty, translation.input.save])

  return (
    <Box display="flex">
      {customButton}
      {UndoOrClose}
      {Remove}
      {Save}

      {entity === 'predictive-campaigns' && campaign?.isRunning ? (
        <CampaignConfirmDialog
          show={showDialog}
          hide={removeWithConfirmation}
          title={translation.alert.deleteCampaign.title}
          message={translation.alert.deleteCampaign.body}
          translation={translation}
        />
      ) : (
        <ConfirmDialog
          show={showDialog}
          hide={removeWithConfirmation}
          title={translation.alert.deleteConfirm.title}
          message={translation.alert.deleteConfirm.body}
        />
      )}
    </Box>
  )
}
