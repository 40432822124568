import { getTranslation } from '../../../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import StatCard from '../common/StatCard'

const Calls: React.FC = () => {
  const translation = useSelector(getTranslation)

  return (
    <Container>
      <StatCard
        entity="calls"
        type="line"
        title={translation.stats.calls.byInterval.title}
        description={translation.stats.calls.byInterval.description}
        path="/tools/stats/calls/report-calls-by-interval"
      />
      <StatCard
        entity="calls"
        type="line"
        title={translation.stats.calls.dailyConcentration.title}
        description={translation.stats.calls.dailyConcentration.description}
        path="/tools/stats/calls/daily-concentration"
      />
      <StatCard
        entity="calls"
        type="donut"
        title={translation.stats.calls.handledCalls.title}
        description={translation.stats.calls.handledCalls.description}
        path="/tools/stats/calls/handled-calls"
      />
      <StatCard
        entity="calls"
        type="table"
        title={translation.stats.calls.contactCenterTimes.title}
        description={translation.stats.calls.contactCenterTimes.description}
        path="/tools/stats/calls/contact-center-times"
      />
      <StatCard
        entity="calls"
        type="table"
        title={translation.stats.calls.handledCallsByHourBands.title}
        description={translation.stats.calls.handledCallsByHourBands.description}
        path="/tools/stats/calls/handled-by-hourbands"
      />
    </Container>
  )
}

export default Calls

const Container = styled(ScrollBar)`
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-template-rows: repeat(auto-fit, 240px);
  gap: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
`
