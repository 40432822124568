import React from "react";
import {InstanceType} from "../SocialSummary";
import styled from "styled-components";
import TokenWarning from "./TokenWarning";
import HeaderType from "./HeaderType";


interface Props{
  type: InstanceType
  isTokenExpired?: boolean
}



const SocialHeader: React.FC<Props>  = ({type, isTokenExpired})=>{
  return(
    <HeadersContainer>
      <HeaderType type={type}/>
      {isTokenExpired && <TokenWarning/>}
    </HeadersContainer>
  )
}

const HeadersContainer = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    
    justify-content: start;
    align-items: center;
    
    gap: 10px;
`

export default SocialHeader


